var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isShow,
          expression: "isShow",
        },
      ],
      class: [_vm.isShowOnScroll ? "fixed" : ""],
      attrs: { id: "common-header" },
    },
    [
      !_vm.isMobile
        ? [
            _c("div", { staticClass: "content" }, [
              _c("a", { attrs: { href: _vm.Href_BASE_URL } }, [
                _c("img", {
                  staticClass: "logo",
                  attrs: {
                    src: "https://img-public.julyedu.com/Public/img/index/logo.png",
                    alt: "logo",
                  },
                }),
              ]),
              _vm._v(" "),
              _c(
                "ul",
                { staticClass: "tabs" },
                _vm._l(_vm.tabs, function (item, index) {
                  return _c("li", { key: item.text }, [
                    item.text == "免费课程"
                      ? _c("span", { staticClass: "new-tip" }, [_vm._v("hot")])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        class: { active: _vm.currentIndex == index },
                        attrs: { href: item.href, target: "_blank" },
                      },
                      [_vm._v(_vm._s(item.text))]
                    ),
                  ])
                }),
                0
              ),
              _vm._v(" "),
              _c("div", { staticClass: "right fr" }, [
                _c("div", { staticClass: "search-fields" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchWord,
                        expression: "searchWord",
                      },
                    ],
                    class: { "search--active": _vm.searchFocused },
                    attrs: {
                      id: "search",
                      type: "text",
                      placeholder: _vm.initDefaultKeyword,
                      autocomplete: "off",
                    },
                    domProps: { value: _vm.searchWord },
                    on: {
                      focus: function ($event) {
                        _vm.searchFocused = true
                      },
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.search.apply(null, arguments)
                      },
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.searchWord = $event.target.value
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    { attrs: { for: "search" }, on: { click: _vm.search } },
                    [
                      _c("i", { staticClass: "iconfont icon-RectangleCopy2" }),
                      _vm._v("\n                        搜索"),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.searchFocused
                    ? _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "click-outside",
                              rawName: "v-click-outside",
                              value: _vm.vcoConfig,
                              expression: "vcoConfig",
                            },
                          ],
                          staticClass: "search-keywords",
                        },
                        [
                          _vm.searchHistory.length
                            ? _c(
                                "div",
                                { staticClass: "search-fields-wrapper" },
                                [
                                  _c("div", { staticClass: "title" }, [
                                    _vm._v("历史搜索"),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "ul",
                                    { staticClass: "history" },
                                    _vm._l(_vm.searchHistory, function (item) {
                                      return _c("li", { key: item }, [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href:
                                                _vm.Href_BASE_URL +
                                                "/search?words=" +
                                                encodeURIComponent(item),
                                              target: "_blank",
                                            },
                                          },
                                          [_vm._v(" " + _vm._s(item))]
                                        ),
                                        _vm._v(" "),
                                        _c("i", {
                                          staticClass:
                                            "iconfont icon-RectangleCopy",
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeHistoryItem(item)
                                            },
                                          },
                                        }),
                                      ])
                                    }),
                                    0
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.hotWords.length
                            ? _c(
                                "div",
                                { staticClass: "search-fields-wrapper" },
                                [
                                  _c("div", { staticClass: "title" }, [
                                    _vm._v("热门搜索"),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "ul",
                                    { staticClass: "hot" },
                                    _vm._l(
                                      _vm.hotWords.slice(1),
                                      function (item) {
                                        return _c("li", { key: item }, [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href:
                                                  _vm.Href_BASE_URL +
                                                  "/search?words=" +
                                                  encodeURIComponent(item),
                                                target: "_blank",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.search(item)
                                                },
                                              },
                                            },
                                            [_vm._v(_vm._s(item))]
                                          ),
                                        ])
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "scholarship",
                    attrs: {
                      href: _vm.Href_BASE_URL + "/distribution/index",
                      target: "_blank",
                    },
                  },
                  [_vm._v("赚奖学金")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "shop-cart" }, [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: _vm.Href_BASE_URL + "/cart",
                        target: "_blank",
                      },
                    },
                    [
                      _vm.shopCartList.length > 0
                        ? _c("sup", { staticClass: "badge" }, [
                            _vm._v(_vm._s(_vm.shopCartList.length)),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("i", { staticClass: "iconfont iconcaigou1" }),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _vm.isLogin
                  ? _c(
                      "a",
                      {
                        attrs: {
                          href: _vm.Href_BASE_URL + "/my/courses",
                          target: "_blank",
                        },
                      },
                      [_vm._v("我的课程")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.isLogin
                  ? _c("div", { staticClass: "user" }, [
                      _c("img", {
                        staticClass: "avatar",
                        attrs: { src: _vm.user.avatar_file, alt: "" },
                      }),
                      _vm._v(" "),
                      _c(
                        "ul",
                        { staticClass: "user-menu" },
                        [
                          _vm._l(_vm.userMenu, function (item) {
                            return _c("li", { key: item.text }, [
                              _c(
                                "a",
                                {
                                  attrs: { href: item.href, target: "_blank" },
                                },
                                [
                                  _c("i", { class: ["iconfont", item.icon] }),
                                  _vm._v(" "),
                                  _c("span", [_vm._v(_vm._s(item.text))]),
                                ]
                              ),
                            ])
                          }),
                          _vm._v(" "),
                          _c("li", { staticClass: "logout" }, [
                            _c(
                              "button",
                              {
                                attrs: { type: "button" },
                                on: { click: _vm.logout },
                              },
                              [_vm._v("安全退出")]
                            ),
                          ]),
                        ],
                        2
                      ),
                    ])
                  : _c(
                      "a",
                      { staticClass: "sign-up", on: { click: _vm.toLogin } },
                      [_vm._v("登录/注册")]
                    ),
                _vm._v(" "),
                (_vm.rsm.comment_unread > 0 ||
                  _vm.rsm.inbox_num > 0 ||
                  _vm.rsm.like_unread > 0) &&
                _vm.showTip
                  ? _c("div", { staticClass: "message_tip" }, [
                      _c("i", {
                        staticClass: "icon iconfont iconguanbi5",
                        attrs: { id: "messageClose" },
                        on: { click: _vm.closeTip },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "message_con" }, [
                        _vm.rsm.comment_unread > 0
                          ? _c("div", { staticClass: "message_info" }, [
                              _c("span", [
                                _c("span", { staticClass: "red" }, [
                                  _vm._v(_vm._s(_vm.rsm.comment_unread)),
                                ]),
                                _vm._v("条评论 , "),
                              ]),
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: _vm.Href_BASE_URL + "/user/comment",
                                  },
                                },
                                [_vm._v("查看评论")]
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.rsm.inbox_num > 0
                          ? _c("div", { staticClass: "message_info" }, [
                              _c("span", [
                                _c("span", { staticClass: "red" }, [
                                  _vm._v(_vm._s(_vm.rsm.inbox_num)),
                                ]),
                                _vm._v("条私信 , "),
                              ]),
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: _vm.Href_BASE_URL + "/user/comment",
                                  },
                                },
                                [_vm._v("点击查看")]
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.rsm.like_unread > 0
                          ? _c("div", { staticClass: "message_info" }, [
                              _c("span", [
                                _c("span", { staticClass: "red" }, [
                                  _vm._v(_vm._s(_vm.rsm.like_unread)),
                                ]),
                                _vm._v("条赞 , "),
                              ]),
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: _vm.Href_BASE_URL + "/user/comment",
                                  },
                                },
                                [_vm._v("点击查看")]
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ])
                  : _vm._e(),
              ]),
            ]),
          ]
        : [
            _c("div", { staticClass: "content" }, [
              _vm._m(0),
              _vm._v(" "),
              _c("div", { staticClass: "btns" }, [
                _vm.$root.isLogin
                  ? _c(
                      "div",
                      {
                        staticClass: "avatar",
                        class: { active: _vm.mobileShowMenu === 1 },
                        on: {
                          click: function ($event) {
                            _vm.mobileShowMenu =
                              _vm.mobileShowMenu === 1 ? 0 : 1
                          },
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: _vm.$root.userInfo.avatar_file,
                            alt: "",
                          },
                        }),
                      ]
                    )
                  : _c("div", { staticClass: "login-btn" }, [
                      _c("a", { attrs: { href: _vm.loginUrl } }, [
                        _c("i", { staticClass: "iconfont iconyonghu1" }),
                        _vm._v(
                          "\n                        登录/注册\n                    "
                        ),
                      ]),
                    ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "m-drop-button",
                    class: { active: _vm.mobileShowMenu === 2 },
                    on: {
                      click: function ($event) {
                        _vm.mobileShowMenu = _vm.mobileShowMenu === 2 ? 0 : 2
                      },
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "iconfont iconfenlei",
                      style: {
                        color: _vm.mobileShowMenu === 2 ? "#fff" : "#000",
                      },
                    }),
                  ]
                ),
              ]),
              _vm._v(" "),
              _vm.mobileShowMenu === 1
                ? _c(
                    "ul",
                    { staticClass: "personal-links links" },
                    [
                      _vm._l(_vm.userMenu, function (item) {
                        return _c("li", [
                          _c("a", { attrs: { href: item.href } }, [
                            _c("i", {
                              staticClass: "iconfont",
                              class: [item.icon],
                            }),
                            _vm._v(
                              "\n                        " +
                                _vm._s(item.text) +
                                "\n                    "
                            ),
                          ]),
                        ])
                      }),
                      _vm._v(" "),
                      _c("li", { on: { click: _vm.logout } }, [
                        _c("i", { staticClass: "iconfont icontuichu4" }),
                        _vm._v("退出\n                "),
                      ]),
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.mobileShowMenu === 2
                ? _c(
                    "ul",
                    { staticClass: "page-links links" },
                    [
                      _c("li", [
                        _c("a", { attrs: { href: _vm.Href_BASE_URL } }, [
                          _c("i", { staticClass: "iconfont iconshouye" }),
                          _vm._v(
                            "\n                        首页\n                    "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.tabs, function (item) {
                        return item.text !== "免费课程"
                          ? _c("li", [
                              _c(
                                "a",
                                {
                                  attrs: { href: item.href, target: "_blank" },
                                },
                                [
                                  _c("i", {
                                    staticClass: "iconfont",
                                    class: [item.icon],
                                  }),
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(item.text) +
                                      "\n                    "
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e()
                      }),
                      _vm._v(" "),
                      _c("li", [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.Href_BASE_URL + "/distribution/index",
                              target: "_blank",
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "iconfont iconfenxiangzhuanqian",
                            }),
                            _vm._v(
                              "\n                        赚奖学金\n                    "
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.Href_BASE_URL + "/cart/",
                              target: "_blank",
                            },
                          },
                          [
                            _c("i", { staticClass: "iconfont icongouwuche" }),
                            _vm._v(
                              "\n                        购物车\n                    "
                            ),
                          ]
                        ),
                      ]),
                    ],
                    2
                  )
                : _vm._e(),
            ]),
          ],
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { attrs: { href: "https://www.julyedu.com" } }, [
      _c("img", {
        staticClass: "logo",
        attrs: { src: "https://cdn.julyedu.com/images/ask/logo.png", alt: "" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
<template>
  <Container>
    <div id="index" :class="{ m: isMobile }">
      <ul class="tabs">
        <li
          v-for="item in tabs"
          :key="item.cate_id"
          :class="{
            active:
              categoryId === item.cate_id ||
              (item.second_level && item.second_level.findIndex(sub => sub.cate_id == categoryId) !== -1),
          }"
        >
          <a :href="`/index?cate_id=${item.cate_id}`"> {{ item.cate_name }}</a>
          <ul class="sub-tabs" v-if="item.second_level">
            <li v-for="subtab in item.second_level" :key="subtab.cate_id">
              <a :href="`/index?cate_id=${subtab.cate_id}`"> {{ subtab.cate_name }}</a>
            </li>
          </ul>
        </li>
      </ul>
      <div class="content">
        <div class="posts">
          <ul v-if="posts.length" class="post-list">
            <li v-for="item in posts" :key="item.question_id">
              <a :href="`/detail?id=${item.question_id}`">
                <div class="avatar">
                  <img :src="item.avatar_file" alt="" />
                </div>
                <div class="post-content">
                  <div class="title">{{ item.question_content }}</div>
                  <div class="bar">
                    <div class="op">
                      <span>
                        <i class="iconfont icondianzan1"></i>
                        {{ item.agree_count }}
                      </span>
                      <span>
                        <i class="iconfont iconpinglun"></i>
                        {{ item.comment_count }}
                      </span>
                      <span>
                        <i class="iconfont iconliulan02"></i>
                        {{ item.view_count }}
                      </span>
                      <button class="set-top" v-if="topPermission" @click="setToTop(item, $event)">
                        {{ item.is_top ? '取消置顶' : '置顶' }}
                      </button>
                    </div>
                    <div class="info">
                      <span v-if="!isMobile">{{ item.user }}</span>
                      <span>{{ item.add_time }}</span>
                    </div>
                  </div>
                </div>
              </a>
            </li>
          </ul>
          <div style="padding-left: 21px;" v-if="totalPost">
            <Pagination :segment.sync="segment" :page.sync="currentPage" :current="currentPage" :total="totalPost" />
          </div>
        </div>
        <div class="related-news" v-if="!isMobile">
          <UserCard :isShowPopup="true" v-if="$root.isLogin" />
          <InfoCard :title="'热门活动'" class="activity-card" v-if="ad && ad.path">
            <span class="update-time" slot="side">{{ ad.update_time }} 更新</span>
            <div class="cover">
              <a :href="ad.jump_url" target="_blank">
                <img :src="ad.path" alt="" />
              </a>
            </div>
          </InfoCard>
          <InfoCard
            :title="'推荐课程'"
            v-if="recommends.length"
            class="recommends"
            :class="{ 'has-swiper': recommends.length > 2 }"
          >
            <div class="side" slot="side" v-if="recommends.length > 2">
              <button class="swiper-prev" @click="swiper.slidePrev()"><i class="iconfont iconxiangxia"></i></button>
              <button class="swiper-next" @click="swiper.slideNext()"><i class="iconfont iconxiangshang"></i></button>
            </div>
            <div class="swiper-container" ref="recommendSwiper" v-if="recommends.length > 2">
              <ul class="swiper-wrapper">
                <li class="swiper-slide" v-for="item in recommends" :key="item.course_id">
                  <a :href="getDetailUrl(item.course_id)" target="_blank" @click="glance(item, $event)">
                    <img :src="item.image_name" alt="" />
                  </a>
                </li>
              </ul>
            </div>
            <div class="no-swiper" v-else>
              <ul>
                <li v-for="item in recommends" :key="item.course_id">
                  <a :href="getDetailUrl(item.course_id)" target="_blank" @click="glance(item, $event)">
                    <img :src="item.image_name" alt="" />
                  </a>
                </li>
              </ul>
            </div>
          </InfoCard>
          <div class="contact">
            <ul class="contact-tab">
              <!--              <li @mouseenter="contactComponent = 1" :class="{ active: contactComponent === 1 }">联系我们</li>-->
              <li @mouseenter="contactComponent = 2" :class="{ active: contactComponent === 1 }">在线咨询</li>
            </ul>
            <!--<div class="contact-content" v-if="contactComponent === 1">
                          <div class="phone">
                            <i class="iconfont icondianhua"></i>
                            <span>010-82712840</span>
                          </div>
                          <div>七月在线全国咨询热线</div>
                        </div>-->
            <div class="contact-content">
              <div class="wx">
                <i class="iconfont iconzixun1"></i>
              </div>
              <a href="http://q.url.cn/s/Vbkup6m?_type=wpa" class="consult" target="_blank">在线咨询</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Container>
</template>

<script>
import Container from 'common/container'
import { getParam } from 'utils/index'
import Pagination from 'common/pagination'
import UserCard from 'common/user-card'
import InfoCard from 'common/info-card'
import { Message } from 'element-ui'
import Swiper from 'swiper'
import { isMobile } from 'mobile-device-detect'

export default {
  name: 'App',
  components: {
    Container,
    Pagination,
    UserCard,
    InfoCard,
    Message,
  },
  data() {
    return {
      tabs: [],
      page: getParam('page'),
      posts: [],
      topPermission: false,
      postCount: 0,
      categoryId: Number(getParam('cate_id')),
      currentPage: 1,
      totalPost: 0,
      segment: 30,
      recommends: [],
      swiper: null,
      ad: {},
      contactComponent: 1,
      isMobile: isMobile,
    }
  },
  created() {
    if (this.isMobile) {
      document.body.classList.add('m')
    }
    this.getCategory()
    this.getPosts()
    this.getRecommends()
  },
  watch: {
    segment() {
      this.getPosts()
    },
    currentPage() {
      this.getPosts()
    },
  },
  methods: {
    getCategory() {
      this.$http.get('/category').then(res => {
        const { code, msg, data } = res.data
        if (code === 200) {
          this.tabs = [{ cate_id: 0, cate_name: '全部' }].concat(data)
        } else {
          Message.error(msg)
        }
      })
    },
    getPosts() {
      this.$http
        .post('/questionByPageCateAjax', {
          page: this.currentPage,
          page_list_num: this.segment,
          cate_id: this.categoryId,
        })
        .then(res => {
          const { code, msg, data } = res.data
          if (code === 200) {
            this.posts = data.list
            this.topPermission = data.permissions.top_article
            this.totalPost = data.count
            this.ad = data.ad
            window.scrollTo(0, 0)
          } else {
            Message.error(msg)
          }
        })
    },
    setToTop(post, e) {
      e.preventDefault()
      e.stopPropagation()
      this.$http
        .post('/setTop', {
          question_id: post.question_id,
        })
        .then(res => {
          const { code, msg } = res.data
          if (code === 200) {
            this.posts = this.posts.map((item, i) => {
              if (item.question_id === post.question_id) {
                item.is_top = +!item.is_top
              }
              return item
            })
          } else {
            Message.error(msg)
          }
        })
    },
    getRecommends() {
      this.$http.get('/home/recommend_courses').then(res => {
        const { code, msg, data } = res.data
        if (code === 200) {
          this.recommends = data
          if (this.recommends.length > 2) {
            this.$nextTick(() => {
              this.initSwiper()
            })
          }
        }
      })
    },
    initSwiper() {
      this.swiper = new Swiper(this.$refs.recommendSwiper, {
        direction: 'vertical',
        slidesPerView: 2,
      })
    },
    getDetailUrl(courseId) {
      return `${EDU}/course/getDetail/${courseId}`
    },
    glance(course, e) {
      e.preventDefault()
      this.getCredit(7).then(() => {
        window.open(this.getDetailUrl(course.course_id))
      })
    },
    getCredit(taskId) {
      return this.$http.post('/integral/receive', {
        task_id: taskId,
      })
    },
  },
}
</script>

<style lang="scss">
@import 'src/styles/common';

body.m {
  width: 100vw;
  overflow-x: hidden;

  #index {
    width: 100vw;

    .tabs {
      padding: 0 10px;
    }

    .post-list {
      padding: 0 10px;
      li {
        height: auto;
        padding-bottom: 10px;
        .title {
          line-height: 20px;
        }
      }
    }
  }
}

#index {
  max-width: 1200px;
  margin: 0 auto;

  .tabs {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    margin-top: 26px;
    width: 100%;
    border-bottom: 1px solid #eff1f3;

    a {
      color: inherit;
      text-decoration: inherit;
    }

    > li {
      position: relative;
      padding: 0 20px;
      font-size: 16px;
      color: #2f3742;
      line-height: 44px;

      &.active {
        border-bottom: 2px solid #09f;
        color: #09f;
      }

      &:hover {
        .sub-tabs {
          display: block;
        }
      }
    }

    .sub-tabs {
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 130px;
      padding: 0 10px;
      background: #fff;
      box-shadow: -3px 1px 8px 0 rgba(27, 27, 28, 0.19);
      z-index: 10;

      li {
        text-align: center;
        line-height: 36px;
        color: #2f3741;
        font-size: 16px;
        border-bottom: 1px solid #edeff1;
      }
    }
  }

  .content {
    display: flex;
    justify-content: space-between;
  }

  .posts {
    width: 830px;
    margin-bottom: 33px;

    .avatar {
      margin-right: 10px;

      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
    }

    .post-content {
      flex: auto;

      .title {
        margin-bottom: 27px;
        font-size: 16px;
        font-weight: 500;
        @include font();
      }

      .bar {
        display: flex;
        justify-content: space-between;
      }

      .op,
      .info {
        font-size: 12px;
        color: #687583;
        line-height: 24px;

        span {
          margin-right: 20px;
          vertical-align: middle;

          .iconfont {
            font-size: 12px;
          }
        }
      }

      .set-top {
        font-size: 12px;
        color: #687583;
        cursor: pointer;
        @include resetButton;
      }

      .op span:nth-child(1),
      .op span:nth-child(2) {
        cursor: pointer;
      }
    }
  }

  .post-list {
    margin-bottom: 33px;

    li {
      height: 120px;
      padding-top: 37px;
      border-bottom: 1px solid #eff1f3;

      a {
        display: flex;
        height: 100%;
        text-decoration: none;
        color: initial;
      }
    }
  }

  .related-news {
    padding-top: 10px;
  }

  .user-card {
    margin-bottom: 16px;
  }

  .activity-card-container {
    img {
      width: 308px;
    }
  }

  .activity-card {
    margin-bottom: 16px;

    .cover {
      text-align: center;
    }

    h4 {
      align-items: flex-end;
    }

    .update-time {
      color: #687583;
      font-size: 12px;
    }
  }

  .recommends {
    &.has-swiper {
      padding-bottom: 0;
    }

    .swiper-container {
      height: 475px;
    }

    .no-swiper {
      li:nth-child(n-1) {
        margin-top: 20px;
      }
    }

    h4 {
      align-items: flex-end;
    }

    .iconfont {
      font-size: 12px;
    }

    button {
      @include resetButton;
      width: 18px;
      height: 18px;
      padding: 0;
      background: #fafafa;
      cursor: pointer;
      color: #798491;

      &:active {
        background: #09f;
        color: #fff;
      }
    }
  }

  .contact {
    $contactHeight: 186px;
    height: $contactHeight;
    margin-top: 16px;
    margin-bottom: 86px;
    border: 1px solid #eff1f4;

    &-tab {
      display: flex;
      height: 50px;
      background: #fafafa;

      li {
        width: 100%;
        height: 100%;
        color: #2f3742;
        line-height: 50px;
        text-align: center;

        &.active {
          background: #09f;
          color: #fff;
        }
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: $contactHeight - 50px;
    }

    .phone {
      margin-bottom: 12px;
      color: #09f;
      letter-spacing: 1px;

      span {
        font-size: 22px;
        @include font('Impact');
      }
    }

    div:last-child(1) {
      color: #2f3742;
      font-size: 16px;
      letter-spacing: 2px;
    }

    .iconfont {
      font-size: 22px;
    }

    .wx {
      .iconfont {
        font-size: 36px;
        color: #09f;
      }
    }

    .consult {
      display: block;
      width: 118px;
      height: 32px;
      color: #fff;
      font-size: 16px;
      line-height: 32px;
      text-align: center;
      background: #0099ff;
      border-radius: 16px;
      text-decoration: none;
    }
  }
}
.icon-RectangleCopy{
    font-size: 20px!important;
    border: 1px solid #ccc;
    border-radius: 50%;
    padding: 4px 8px;
    color:#999;
    &::before{
      content:"X";
      display: block;
    }
}

</style>

<template>
    <div class="btns">
        <button @click="publish">发帖</button>
        <button @click="sign" v-if="!isSign">签到</button>
        <button disabled v-else>已签到</button>
        <transition name="sign" enter-active-class="animate__animated animate__slideInUp animate__faster"
                    leave-active-class="animate__animated animate__slideOutDown animate__faster">
            <div class="signed" v-if="isShowAddedCredit"><span>+{{score}}</span>积分</div>
        </transition>
        <div class="tip sign-success" v-if="isShowGuidance">
            <div class="title">签到成功</div>
            <div class="tip-content">
                完成<span>【积分中心】</span>的各种任务<br> 可以获得更多积分哦～
            </div>
            <button @click="isShowGuidance = false">我知道了</button>
            <div class="info">点击积分即可进入【积分中心】</div>
        </div>
        <div class="tip" v-if="isShowTip">
            <div class="tip-content">每天登录后都可以在这签到<br> 领取积分</div>
            <button class="close" @click="isShowTip = false">我知道了</button>
        </div>
    </div>
</template>

<script>
  import debounce from 'lodash/debounce'
  import { Message } from 'element-ui'

  export default {
    name: 'buttons',
    props: ['isSign', 'isShowPopup'],
    components: {
      Message,
    },
    data() {
      return {
        isShowTip: false,
        isShowGuidance: false,
        isShowAddedCredit: false,
        score: '',
      }
    },
    watch: {
      isSign:{
        immediate: true,
        handler(val){
          this.isShowTip = !!(!val && this.isShowPopup)
        },
      }
    },
    methods: {
      publish() {
        window.open('/posting')
      },
      sign: debounce(function () {
        if (this.isSign || this.isShowTip) {
          return
        }
        this.$http.post(`/integral/receive`, {
          task_id: 6,
        }).then(res => {
          const {code, msg, data} = res.data
          if (code === 200) {
            this.$emit('update:isSign', 1)
            this.score = data.score
            if (this.isShowPopup) {
              this.isShowGuidance = this.isShowAddedCredit = true
              setTimeout(() => {
                this.isShowAddedCredit = false
              }, 2000)
            }
          } else {
            Message.error(msg)
          }
        })
      }, 300),
    },
  }
</script>

<style scoped lang="scss">
    @import 'src/styles/common';

    .btns {
        position: relative;
        text-align: center;

        > button {
            width: 98px;
            height: 30px;
            border-radius: 6px;
            font-size: 14px;
            color: #fff;
            @include resetButton;
            cursor: pointer;

            &:nth-child(1) {
                background: #09f;
                margin-right: 37px;
            }

            &:nth-child(2) {
                background: #88c254;
            }

            &[disabled] {
                background: #88898a;
            }
        }

        .signed {
            $signedHeight: 42px;
            position: absolute;
            top: -($signedHeight + 2px);
            right: 48px;
            width: 108px;
            height: $signedHeight;
            background: rgba(14, 14, 15, .75);
            border-radius: 6px;
            color: #fff;
            font-size: 16px;
            line-height: $signedHeight;
            text-align: center;

            span {
                font-size: 24px;
            }
        }
    }

    .tip {
        $tipWidth: 295px;
        $tipHeight: 178px;
        $arrowHeight: 10px;
        position: absolute;
        bottom: -($tipHeight + $arrowHeight);
        right: 0;
        width: $tipWidth;
        height: $tipHeight;
        padding: 45px 40px 0;
        box-shadow: 4px 4px 43px 0 rgba(0, 0, 0, 0.1);
        background: #fff;
        border-radius: 12px;

        &::before {
            $arrowColor: #fff;
            position: absolute;
            top: -$arrowHeight;
            right: 90px;
            content: '';
            display: block;
            width: 0;
            height: 0;
            border-bottom: $arrowHeight solid $arrowColor;
            border-left: $arrowHeight/2 solid transparent;
            border-right: $arrowHeight/2 solid transparent;
            border-top: 0;
        }

        &-content {
            margin-bottom: 20px;
            font-size: 16px;
            line-height: 30px;
            color: #2F3742;
        }

        button {
            width: 138px;
            height: 35px;
            @include resetButton;
            background: #0099FF;
            border-radius: 8px;
            font-size: 14px;
            color: #fff;
            cursor: pointer;
        }

        &.sign-success {
            height: auto;
            padding: 0 20px 20px;
            bottom: -228px;

            .title {
                margin-bottom: 15px;
                font-size: 18px;
                color: #2F3742;
                font-weight: 600;
                line-height: 46px;
                border-bottom: 1px solid #EDEDED;
            }

            .tip-content {
                span {
                    color: #09f;
                }
            }

            .info {
                margin-top: 10px;
                font-size: 12px;
                color: #687583;
            }
        }
    }

</style>

import cookie from 'js-cookie'

export const getParam = (key, str) => {
  const _s = str ? str : location.href;
  const re = new RegExp(`[?#&](${key})=([^=&#?]+)`, 'ig');
  let found;
  return (found = re.exec(_s)) ? found[2] : null;
}

export const clearCookie = () => {
  const cookies = ['avatar_file', 'email', 'token', 'uid', 'uname', 'user_name']
  const attr = {
    path: '/',
    domain: '.julyedu.com',
  }
  cookies.forEach(item => {
    cookie.remove(item, attr)
  })
}
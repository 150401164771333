var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("CommonHeader", {
        attrs: {
          isLogin: _vm.$root.isLogin,
          user: _vm.$root.userInfo,
          shopCartList: _vm.shopCartList,
          defaultHotWord: _vm.defaultHotWord,
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "content" }, [_vm._t("default")], 2),
      _vm._v(" "),
      _c("footer", [_c("CommonFooter")], 1),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "activity-card-container" },
    [
      _c(
        "h4",
        [
          _c("span", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
          _vm._v(" "),
          _vm._t("side"),
        ],
        2
      ),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <header id="common-header" v-show="isShow" :class="[isShowOnScroll ? 'fixed' : '']">
        <template v-if="!isMobile">
            <div class="content">
                <a :href="Href_BASE_URL">
                    <img src="https://img-public.julyedu.com/Public/img/index/logo.png"
                         class="logo"
                         alt="logo">
                </a>
                <ul class="tabs">
                    <li v-for="(item,index) in tabs" :key="item.text">
                        <span class="new-tip" v-if="item.text == '免费课程'">hot</span>
                        <a :href="item.href" :class="{active: currentIndex == index}" target="_blank">{{item.text}}</a>
                    </li>
                </ul>
                <div class="right fr">
                    <div class="search-fields">
                        <input id="search"
                               :class="{'search--active': searchFocused}"
                               type="text"
                               :placeholder="initDefaultKeyword"
                               @focus="searchFocused = true"
                               autocomplete="off"
                               v-model="searchWord"
                               @keyup.enter="search"
                        >
                        <label for="search" @click="search">
                            <i class="iconfont icon-RectangleCopy2"></i>
                            搜索</label>
                        <div class="search-keywords" v-if="searchFocused" v-click-outside="vcoConfig">
                            <div class="search-fields-wrapper" v-if="searchHistory.length">
                                <div class="title">历史搜索</div>
                                <ul class="history">
                                    <li v-for="item in searchHistory" :key="item">
                                        <a :href="`${Href_BASE_URL}/search?words=${encodeURIComponent(item)}`"
                                           target="_blank"> {{item}}</a>
                                        <i class="iconfont icon-RectangleCopy" @click="removeHistoryItem(item)"></i>
                                    </li>
                                </ul>
                            </div>
                            <div class="search-fields-wrapper" v-if="hotWords.length">
                                <div class="title">热门搜索</div>
                                <ul class="hot">
                                    <li v-for="item in hotWords.slice(1)" :key="item">
                                        <a :href="`${Href_BASE_URL}/search?words=${encodeURIComponent(item)}`"
                                           target="_blank"
                                           @click="search(item)">{{item}}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <a class="scholarship" :href="`${Href_BASE_URL}/distribution/index`" target="_blank">赚奖学金</a>
                    <div class="shop-cart">
                        <a :href="`${Href_BASE_URL}/cart`" target="_blank">
                            <sup v-if="shopCartList.length>0" class="badge">{{shopCartList.length}}</sup>
                            <i class="iconfont iconcaigou1"></i>
                        </a>
                    </div>

                    <a :href="`${Href_BASE_URL}/my/courses`" v-if="isLogin" target="_blank">我的课程</a>
                    <div class="user" v-if="isLogin">
                        <img class="avatar"
                             :src="user.avatar_file"
                             alt="">
                        <ul class="user-menu">
                            <li v-for="item in userMenu" :key="item.text">
                                <a :href="item.href" target="_blank">
                                    <i :class="['iconfont', item.icon]"></i>
                                    <span>{{item.text}}</span>
                                </a>
                            </li>
                            <li class="logout">
                                <button type="button" @click="logout">安全退出</button>
                            </li>
                        </ul>
                    </div>
                    <a v-else class="sign-up" @click="toLogin">登录/注册</a>
                    <div class="message_tip"
                         v-if="(rsm.comment_unread > 0 || rsm.inbox_num > 0 || rsm.like_unread > 0) && showTip">
                        <!-- 消息提示框 -->
                        <i class="icon iconfont iconguanbi5" id='messageClose' @click="closeTip"></i>
                        <div class="message_con">

                            <div v-if="rsm.comment_unread > 0" class="message_info">
                                <span><span class='red'>{{rsm.comment_unread}}</span>条评论 , </span><a
                                    :href="`${Href_BASE_URL}/user/comment`">查看评论</a>
                            </div>
                            <div v-if="rsm.inbox_num > 0" class="message_info">
                                <span><span class='red'>{{rsm.inbox_num}}</span>条私信 , </span><a
                                    :href="`${Href_BASE_URL}/user/comment`">点击查看</a>
                            </div>
                            <div v-if="rsm.like_unread > 0" class="message_info">
                                <span><span class='red'>{{rsm.like_unread}}</span>条赞 , </span><a
                                    :href="`${Href_BASE_URL}/user/comment`">点击查看</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="content">
              <a href="https://www.julyedu.com">
                <img class="logo" src="https://cdn.julyedu.com/images/ask/logo.png" alt="">
                </a>
                <div class="btns">
                    <div class="avatar" :class="{active: mobileShowMenu === 1}" @click="mobileShowMenu = mobileShowMenu === 1 ? 0 : 1" v-if="$root.isLogin">
                        <img :src="$root.userInfo.avatar_file" alt="">
                    </div>
                    <div v-else class="login-btn">
                        <a :href="loginUrl">
                            <i class="iconfont iconyonghu1"></i>
                            登录/注册
                        </a>
                    </div>
                    <div class="m-drop-button" :class="{active: mobileShowMenu === 2}" @click="mobileShowMenu = mobileShowMenu === 2 ? 0 : 2">
                        <i class="iconfont iconfenlei" :style="{color: mobileShowMenu === 2 ? '#fff' : '#000'}"></i>
                    </div>
                </div>
                <ul class="personal-links links" v-if="mobileShowMenu === 1">
                    <li v-for="item in userMenu">
                        <a :href="item.href">
                            <i class="iconfont" :class="[item.icon]"></i>
                            {{item.text}}
                        </a>
                    </li>
                    <li @click="logout">
                        <i class="iconfont icontuichu4"></i>退出
                    </li>
                </ul>
                <ul class="page-links links" v-if="mobileShowMenu === 2">
                    <li>
                        <a :href="Href_BASE_URL">
                            <i class="iconfont iconshouye"></i>
                            首页
                        </a>
                    </li>
                    <li v-for="item in tabs" v-if="item.text !== '免费课程'">
                        <a :href="item.href" target="_blank">
                            <i class="iconfont" :class="[item.icon]"></i>
                            {{item.text}}
                        </a>
                    </li>
                    <li>
                        <a :href="`${Href_BASE_URL}/distribution/index`" target="_blank">
                            <i class="iconfont iconfenxiangzhuanqian"></i>
                            赚奖学金
                        </a>
                    </li>
                    <li>
                        <a :href="`${Href_BASE_URL}/cart/`" target="_blank">
                            <i class="iconfont icongouwuche"></i>
                            购物车
                        </a>
                    </li>
                </ul>
            </div>
        </template>
    </header>
</template>

<script>
  import vClickOutside from 'v-click-outside'
  import throttle from 'lodash/throttle'
  import { clearCookie } from "../../utils";
  import {isMobile} from 'mobile-device-detect'

  export default {
    name: "common-header",
    data() {
      return {
        Href_BASE_URL: EDU,
        tabs: [
          {
            text: '精品课程',
            href: `${EDU}/category/index`,
            icon: 'iconzhaokecheng1'
          },
          // {
          //     text: '限时免费',
          //     href: '/LimitCourse/index'
          // },
          {
            text: '面试题库',
            // href: `${BASE_URL}/question/index`,
            href: `${EDU}/questions/written`,
            icon: 'iconmianshitiku'
          },
          {
            text: '免费课程',
            href: `${EDU}/category/index?type=free#free`,
          },
          // {
          //     text: '竞赛',
          //     href: 'http://jingsai.julyedu.com/'
          // },
          // {
          //     text: '求职',
          //     href: 'https://job.julyedu.com'
          // },
          {
            text: '社区',
            href: '/index/',
            icon: 'icontubiao_lianmeng-lianlianmenghemaishequ'
          },
          {
            text: '企业内训',
            href: `${EDU}/active/qiye`,
            icon: 'iconshejiao-hezuo1'
          },
        ],
        userMenu: [
          {
            text: '个人主页',
            href: `${EDU}/user/set_info`,
            icon: 'iconshouye',
          },
          {
            text: '消息中心',
            href: `${EDU}/user/comment`,
            icon: 'iconqiepian22222',
          },
          {
            text: '订单',
            href: `${EDU}/my/orders`,
            icon: 'icondingdan',
          },
          {
            text: '优惠券',
            href: `${EDU}/my/coupon`,
            icon: 'iconyouhuiquan',
          },
          {
            text: '账号设置',
            href: `${EDU}/passport/wscui`,
            icon: 'iconshezhi',
          },
        ],
        searchHistory: JSON.parse(localStorage.getItem('search')) || [],
        hotWords: [],
        searchFocused: false,
        searchWord: '',
        vcoConfig: {
          handler: this.vcoHandler,
          middleware: this.vcoMiddleware,
          events: ['click'],
        },
        href: location.href,
        isShow: this.isShowOnScroll ? window.scrollY >= 200 : true,
        rsm: {
          comment_unread: 0,
          inbox_num: 0,
          like_unread: 0,
        },
        showTip: true,
        isMobile: isMobile,
        mobileShowMenu: 0,
        loginUrl: `${window.PASSPORT}/login?redirect=${encodeURIComponent(window.location.href)}`
      }
    },
    props: {
      currentIndex: Number,
      isShowOnScroll: Boolean,
      shopCartList: Array,
      defaultHotWord: String,
      user: {
        type: Object,
        default: () => {
          return {};
        },
      },
      isLogin: {
        type: Boolean,
        default: false,
      },
    },
    directives: {
      clickOutside: vClickOutside.directive,
    },
    computed: {
      initDefaultKeyword() {
        return this.defaultHotWord ? this.defaultHotWord : this.hotWords[0];
      },
    },
    created() {
      this.getSearchHotWords()
    },
    mounted() {
      this.getNotifications();
      this.isShowOnScroll && document.addEventListener('scroll', this.showOnScroll)
    },
    methods: {
      getSearchHotWords() {
        this.$http.get(`${SEARCH_URL}/search_hot_word`)
          .then(res => {
            const {data, errno} = res.data
            if (errno === 0) {
              this.hotWords = data.info.hot_words.slice(0, 6)
              sessionStorage.setItem('data', JSON.stringify(data.info))
            }
          })
      },
      search(word) {
        let searchWord = typeof word === 'object' ? this.searchWord ? this.searchWord : this.defaultHotWord ? this.defaultHotWord : this.hotWords[0] : word;
        typeof word === 'object' && window.open(`${window.EDU}/search?words=${encodeURIComponent(searchWord)}`)
      },
      addSearchWords(word) {
        if (this.searchHistory.findIndex(item => item === word) < 0) {
          this.searchHistory.push(word)
          this.storeSearchHistory()
        }
        this.searchWord = word
      },
      searchBlur() {
        setTimeout(() => {
          this.searchFocused = false
        }, 100)
      },
      storeSearchHistory() {
        localStorage.setItem('search', JSON.stringify(this.searchHistory))
      },
      vcoHandler() {
        this.searchFocused = false
      },
      vcoMiddleware(event) {
        return event.target.id !== 'search' && !event.target.classList.contains('icon-RectangleCopy')
      },
      showOnScroll: throttle(function () {
        this.isShow = window.scrollY >= 200
      }, 100),
      logout() {
        clearCookie()
        window.location.reload()
      },
      toLogin: function () {
        window.location.href = PASSPORT + '/login?redirect=' + encodeURIComponent(this.href);
      },
      getNotifications: function () {
        this.$http.get(`${EDU}/notification/notifications/`).then(res => {
          if (res && res.data && Number(res.data.errno) === 1) {
            this.rsm = res.data.rsm;
            // console.log(res.data.rsm);
          }
        });
      },
      closeTip: function () {
        this.showTip = false;
      },
    },
    watch: {
      searchWord(val) {
        val === '' && event.emit('delete_input')
      },
    },
  }
</script>

<style lang="scss">
    body.m {
        #common-header {
            .content {
                position: relative;
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100vw;
                height: 50px;

                .links {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    transform: translateY(100%);
                    padding-left: 20px;
                    background: #fff;
                    z-index: 100;
                    border-top: 1px solid #ddd;
                    border-bottom: 1px solid #ddd;

                    .iconfont{
                        font-size: 22px;
                        margin-right: 10px;
                    }

                    li {
                        height: 40px;
                        line-height: 40px;
                    }
                }

                .logo {
                    width: 96px;
                    margin-left: 5px;
                }

                .btns {
                    display: flex;

                    .login-btn{
                        width: auto;
                        font-size: 16px;
                    }


                    > div {
                        width: 60px;
                        text-align: center;

                        &.active {
                            background: #09f;
                        }
                    }
                }

                .avatar {
                    img {
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                    }
                }

                .m-drop-button {
                    .iconfont{
                        font-size: 22px;
                    }
                }
            }
        }
    }
</style>
<style lang="scss" scoped>
    /* cart */
    .cart-popup__button--buy {
        display: block;
        width: 82px;
        height: 28px;
        line-height: 28px;
        background: linear-gradient(90deg, rgba(255, 64, 0, 1) 0%, rgba(255, 33, 33, 1) 100%);
        border-radius: 14px;
        font-size: 14px;
        color: #fff !important;
        margin: 0 auto;
        text-align: center;
    }

    .cart-popup__content {
        max-height: 323px;
        padding: 0 20px;
        overflow-y: auto;
    }

    /* cart--empty */
    .cart-popup--empty {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 236px;
    }

    .cart-popup__title--empty {
        margin: 0;
        font-size: 12px;
        color: #666;
        text-align: center;
    }

    .cart-popup__link--empty {
        display: block;
        font-size: 12px;
        color: #80CCFF;
        line-height: 28px;
        text-align: center;

        &:hover {
            color: #0099FF;
        }
    }

    .badge {
        position: absolute;
        top: 0px;
        left: 2px;
        transform: translateX(50%);
        width: 22px;
        height: 16px;
        line-height: 16px;
        text-align: center;
        background: rgba(255, 33, 33, 1);
        border-radius: 8px 8px 8px 0;
        font-size: 12px;
        color: #fff;
    }

    .cart-popup__course {
        display: flex;
        justify-content: space-between;
        line-height: 1em;
        margin-left: -10px;
        margin-right: -10px !important;
        padding: 10px;

        &:hover {
            background-color: #F5F9FC;
        }
    }

    .cart-popup__course-cover {
        display: block;
        width: 90px;
        height: 65px;
        margin-right: 12px;
        background-size: cover;
    }

    .cart-popup__course-info {
        flex: 1;
        display: inline-flex;
        flex-direction: column;
        justify-content: space-between;

        .title {
            font-size: 12px;
            color: #333;
        }

        .price {
            font-size: 16px;
            color: #FF2121;

            span {
                font-size: 12px;
            }
        }
    }

    .cart-popup__header {
        padding: 0 20px;
    }

    .cart-popup {
        display: none;
        position: absolute;
        top: 49px;
        right: 0;
        width: 324px;
        padding-bottom: 16px;
        border-radius: 8px;
        background-color: rgba(255, 255, 255, 1);
        font-size: 14px;
        color: #333;
        box-shadow: 0 6px 10px 0 rgba(153, 153, 153, 0.12);
        transform: translateX(40%);
        z-index: 8;

        .list-item-count {
            font-size: 12px;
            color: #999;
        }

        .ivu-divider-horizontal {
            width: 284px;
            min-width: 284px;
            margin: 0 auto 12px;
        }
    }

    $dropdownTopPosition: 50px;
    $searchButtonWidth: 73px;

    #common-header {
        width: 100%;
        font-size: 16px;
        color: #333;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12);
        background: #fff;
        box-sizing: border-box;

        &.fixed {
            position: fixed;
            top: 0;
            left: 0;
            z-index: 999;
        }

        & * {
            box-sizing: border-box;
        }

        .content {
            width: 1200px;
            height: 60px;
            margin: 0 auto;
            line-height: 60px;
            position: relative;

            .message_tip {
                position: absolute;
                right: 0;
                top: 66px;
                width: 225px;
                background-color: #fff9cd;
                font-size: 12px;
                padding: 8px 10px;
                border-radius: 4px;

                #messageClose {
                    position: absolute;
                    top: 12px;
                    right: 10px;
                    height: 18px;
                    line-height: 18px;
                    cursor: pointer;
                }

                .message_info {
                    height: 18px;
                    line-height: 18px;
                    margin: 4px;

                    .red {
                        color: red;
                    }

                    a {
                        color: #09f;
                    }
                }
            }
        }

        img {
            vertical-align: middle;
        }

        .fl {
            float: left;
        }

        .fr {
            float: right;
        }

        .logo {
            width: 115px;
            height: 28px;
            margin-right: 28px;
        }

        a {
            text-decoration: none;
            color: inherit;
        }

        ul {
            margin: 0;
            list-style: none;
            padding: 0;


        }

        .tabs {
            display: inline-flex;
            justify-content: center;

            li {
                margin-right: 23px;
                position: relative;

                .new-tip {
                    display: inline-block;
                    width: 26px;
                    height: 16px;
                    line-height: 16px;
                    text-align: center;
                    background: rgba(255, 33, 33, 1);
                    border-radius: 8px 8px 8px 0;
                    font-size: 12px;
                    color: #fff;
                    position: absolute;
                    right: -16px;
                    top: 5px;
                }

                a {
                    &:hover {
                        color: #09f;

                    }

                    &.active {
                        color: #09f;
                    }
                }

            }

            li:last-child {
                margin-right: 0;
            }
        }

        .right {
            font-size: 14px;
            display: flex;
            align-items: center;

            a {
                margin-right: 24px;

                &:hover {
                    color: #09f;
                }
            }


            .avatar {
                width: 30px;
                height: 30px;
                border-radius: 50%;
            }

        }

        .shop-cart {
            position: relative;
            display: inline-block;

            &:hover {
                .show-cart-list {
                    display: block;
                }

                .cart-popup {
                    display: block;
                }
            }

            .iconfont {
                font-size: 20px;
            }

            .badge {
                position: absolute;
                top: 6px;
                left: 0;
                transform: translateX(50%);
                width: 22px;
                height: 16px;
                line-height: 16px;
                text-align: center;
                background: rgba(255, 33, 33, 1);
                border-radius: 8px 8px 8px 0;
                font-size: 12px;
                color: #fff;
            }

            .show-cart-list {
                position: absolute;
                top: $dropdownTopPosition;
                right: 0;
                transform: translateX(40%);
                width: 324px;
                padding: 0 20px 16px;
                background: rgba(255, 255, 255, 1);
                box-shadow: 0 6px 10px 0 rgba(153, 153, 153, 0.12);
                border-radius: 0 0 8px 8px;
                font-size: 14px;
                color: #333;
                display: none;

                .list-item-count {
                    font-size: 12px;
                    color: #999;
                }

                .ivu-divider-horizontal {
                    margin: 0 0 12px;
                }

                .list-item:last-child {
                    margin-bottom: 20px;
                }

                .list-item {
                    display: flex;
                    justify-content: space-between;
                    line-height: 1em;
                    margin-bottom: 10px;
                    margin-left: -10px;
                    margin-right: -10px;
                    padding: 10px;

                    &:hover {
                        background-color: #F5F9FC;
                    }

                    .course-cover {
                        width: 90px;
                        height: 65px;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .course-info {
                        width: 182px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;

                        .price {
                            font-size: 16px;
                        }

                        p:first-child {
                            font-size: 12px;
                        }

                        p:last-child {
                            color: #FF2121;
                        }
                    }
                }

                a {
                    display: block;
                    width: 82px;
                    height: 28px;
                    line-height: 28px;
                    background: linear-gradient(90deg, rgba(255, 64, 0, 1) 0%, rgba(255, 33, 33, 1) 100%);
                    border-radius: 14px;
                    font-size: 14px;
                    color: #fff;
                    margin: 0 auto;
                    text-align: center;
                }

                .empty {
                    font-size: 12px;
                    color: #666;
                    text-align: center;
                    margin: 100px auto 0;
                }

                .link_to {
                    font-size: 12px;
                    color: #80CCFF;
                    cursor: pointer;
                    background: none;
                    width: 100%;
                    text-align: center;
                    text-align-last: center;

                    &:hover {
                        color: #0099FF;
                    }
                }
            }
        }

        .search-fields {
            display: inline-flex;
            align-items: center;
            height: 100%;
            position: relative;
            margin-right: 24px;

            input {
                width: 189px + $searchButtonWidth;
                height: 36px;
                line-height: 12px;
                padding-left: 15px;
                padding-right: $searchButtonWidth + 2px;
                font-size: 12px;
                border: 1px solid #ccc;
                border-radius: 18px;
                -webkit-appearance: none;
                outline: none;
            }

            .search--active {
                border-color: #09f;
            }

            label {
                position: absolute;
                right: 0;
                top: 0;
                width: $searchButtonWidth;
                height: 36px;
                line-height: 36px;
                background: rgba(0, 153, 255, 1);
                border-radius: 0 18px 18px 0;
                text-align: center;
                color: #fff;
                font-size: 14px;
                cursor: pointer;

                .iconfont {
                    font-size: 14px;
                }
            }

            .search-keywords {
                position: absolute;
                top: $dropdownTopPosition - 5px;
                right: 0;
                background: #fff;
                box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.12);
                border-radius: 0 0 4px 4px;
                padding-bottom: 19px;
                z-index: 9;

                .title {
                    font-size: 12px;
                    color: #666;
                    padding-left: 16px;
                    height: 30px;
                    line-height: 30px;
                    margin-top: 20px;
                }

                ul {
                    width: 262px;

                    li {
                        height: 34px;
                        line-height: 34px;
                        padding: 0 20px 0 15px;
                        font-size: 14px;
                        display: flex;
                        justify-content: space-between;

                        a {
                            display: block;
                            width: 70%;
                        }

                        .iconfont {
                            display: none;
                            color: #adadad;
                        }

                        &:hover {
                            background: #F5F5F5;
                            color: #0099FF;

                            .iconfont {
                                cursor: pointer;
                                display: inline;

                                &:hover {
                                    color: #09f;
                                }
                            }
                        }
                    }
                }
            }
        }

        .user {
            position: relative;
            display: inline-block;

            &:hover {
                .user-menu {
                    display: block;
                }
            }

            &-menu {
                display: none;
                position: absolute;
                top: $dropdownTopPosition;
                right: -50px;
                width: 125px;
                height: 240px;
                box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.12);
                border-radius: 8px;
                z-index: 999;
                background: #fff;

                li {
                    height: 40px;
                    line-height: 40px;
                    padding-left: 16px;
                    margin: 0;

                    a {
                        /* display: block; */
                        display: inline-flex;
                        align-items: center;
                        height: 100%;
                        margin-right: 0;
                    }

                    span {
                        font-size: 14px;
                        color: #333;
                    }

                    &:first-child {
                        border-radius: 8px 8px 0 0;
                    }

                    &:last-child {
                        border-radius: 0 0 8px 8px;
                    }

                    .iconfont {
                        font-size: 18px;
                        margin-right: 8px;
                    }

                    &:hover {
                        background: #F5FBFF;
                    }

                    &.logout {
                        button {
                            display: block;
                            width: 100%;
                            height: 100%;
                            -webkit-appearance: none;
                            outline: none;
                            border: none;
                            padding: 0;
                            font-size: 12px;
                            color: #666;
                            cursor: pointer;
                            background: transparent;
                            text-align: left;
                        }
                    }
                }
            }
        }


    }
</style>
import axios from 'axios'
import qs from 'qs'

const http = axios.create({
  baseURL: window.ASK,
  withCredentials: true,
  transformRequest: [
    data => {
      return data instanceof FormData ? data : qs.stringify(data)
    },
  ],
})

export default function (Vue) {
  Vue.prototype.$http = http
}
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Container", [
    _c("div", { class: { m: _vm.isMobile }, attrs: { id: "index" } }, [
      _c(
        "ul",
        { staticClass: "tabs" },
        _vm._l(_vm.tabs, function (item) {
          return _c(
            "li",
            {
              key: item.cate_id,
              class: {
                active:
                  _vm.categoryId === item.cate_id ||
                  (item.second_level &&
                    item.second_level.findIndex(function (sub) {
                      return sub.cate_id == _vm.categoryId
                    }) !== -1),
              },
            },
            [
              _c("a", { attrs: { href: "/index?cate_id=" + item.cate_id } }, [
                _vm._v(" " + _vm._s(item.cate_name)),
              ]),
              _vm._v(" "),
              item.second_level
                ? _c(
                    "ul",
                    { staticClass: "sub-tabs" },
                    _vm._l(item.second_level, function (subtab) {
                      return _c("li", { key: subtab.cate_id }, [
                        _c(
                          "a",
                          {
                            attrs: { href: "/index?cate_id=" + subtab.cate_id },
                          },
                          [_vm._v(" " + _vm._s(subtab.cate_name))]
                        ),
                      ])
                    }),
                    0
                  )
                : _vm._e(),
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "posts" }, [
          _vm.posts.length
            ? _c(
                "ul",
                { staticClass: "post-list" },
                _vm._l(_vm.posts, function (item) {
                  return _c("li", { key: item.question_id }, [
                    _c(
                      "a",
                      { attrs: { href: "/detail?id=" + item.question_id } },
                      [
                        _c("div", { staticClass: "avatar" }, [
                          _c("img", {
                            attrs: { src: item.avatar_file, alt: "" },
                          }),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "post-content" }, [
                          _c("div", { staticClass: "title" }, [
                            _vm._v(_vm._s(item.question_content)),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "bar" }, [
                            _c("div", { staticClass: "op" }, [
                              _c("span", [
                                _c("i", {
                                  staticClass: "iconfont icondianzan1",
                                }),
                                _vm._v(
                                  "\n                      " +
                                    _vm._s(item.agree_count) +
                                    "\n                    "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("span", [
                                _c("i", {
                                  staticClass: "iconfont iconpinglun",
                                }),
                                _vm._v(
                                  "\n                      " +
                                    _vm._s(item.comment_count) +
                                    "\n                    "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("span", [
                                _c("i", {
                                  staticClass: "iconfont iconliulan02",
                                }),
                                _vm._v(
                                  "\n                      " +
                                    _vm._s(item.view_count) +
                                    "\n                    "
                                ),
                              ]),
                              _vm._v(" "),
                              _vm.topPermission
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "set-top",
                                      on: {
                                        click: function ($event) {
                                          return _vm.setToTop(item, $event)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(
                                            item.is_top ? "取消置顶" : "置顶"
                                          ) +
                                          "\n                    "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "info" }, [
                              !_vm.isMobile
                                ? _c("span", [_vm._v(_vm._s(item.user))])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("span", [_vm._v(_vm._s(item.add_time))]),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                  ])
                }),
                0
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.totalPost
            ? _c(
                "div",
                { staticStyle: { "padding-left": "21px" } },
                [
                  _c("Pagination", {
                    attrs: {
                      segment: _vm.segment,
                      page: _vm.currentPage,
                      current: _vm.currentPage,
                      total: _vm.totalPost,
                    },
                    on: {
                      "update:segment": function ($event) {
                        _vm.segment = $event
                      },
                      "update:page": function ($event) {
                        _vm.currentPage = $event
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]),
        _vm._v(" "),
        !_vm.isMobile
          ? _c(
              "div",
              { staticClass: "related-news" },
              [
                _vm.$root.isLogin
                  ? _c("UserCard", { attrs: { isShowPopup: true } })
                  : _vm._e(),
                _vm._v(" "),
                _vm.ad && _vm.ad.path
                  ? _c(
                      "InfoCard",
                      {
                        staticClass: "activity-card",
                        attrs: { title: "热门活动" },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "update-time",
                            attrs: { slot: "side" },
                            slot: "side",
                          },
                          [_vm._v(_vm._s(_vm.ad.update_time) + " 更新")]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "cover" }, [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: _vm.ad.jump_url,
                                target: "_blank",
                              },
                            },
                            [
                              _c("img", {
                                attrs: { src: _vm.ad.path, alt: "" },
                              }),
                            ]
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.recommends.length
                  ? _c(
                      "InfoCard",
                      {
                        staticClass: "recommends",
                        class: { "has-swiper": _vm.recommends.length > 2 },
                        attrs: { title: "推荐课程" },
                      },
                      [
                        _vm.recommends.length > 2
                          ? _c(
                              "div",
                              {
                                staticClass: "side",
                                attrs: { slot: "side" },
                                slot: "side",
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "swiper-prev",
                                    on: {
                                      click: function ($event) {
                                        return _vm.swiper.slidePrev()
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "iconfont iconxiangxia",
                                    }),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass: "swiper-next",
                                    on: {
                                      click: function ($event) {
                                        return _vm.swiper.slideNext()
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "iconfont iconxiangshang",
                                    }),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.recommends.length > 2
                          ? _c(
                              "div",
                              {
                                ref: "recommendSwiper",
                                staticClass: "swiper-container",
                              },
                              [
                                _c(
                                  "ul",
                                  { staticClass: "swiper-wrapper" },
                                  _vm._l(_vm.recommends, function (item) {
                                    return _c(
                                      "li",
                                      {
                                        key: item.course_id,
                                        staticClass: "swiper-slide",
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: _vm.getDetailUrl(
                                                item.course_id
                                              ),
                                              target: "_blank",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.glance(item, $event)
                                              },
                                            },
                                          },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: item.image_name,
                                                alt: "",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    )
                                  }),
                                  0
                                ),
                              ]
                            )
                          : _c("div", { staticClass: "no-swiper" }, [
                              _c(
                                "ul",
                                _vm._l(_vm.recommends, function (item) {
                                  return _c("li", { key: item.course_id }, [
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: _vm.getDetailUrl(
                                            item.course_id
                                          ),
                                          target: "_blank",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.glance(item, $event)
                                          },
                                        },
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: item.image_name,
                                            alt: "",
                                          },
                                        }),
                                      ]
                                    ),
                                  ])
                                }),
                                0
                              ),
                            ]),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "contact" }, [
                  _c("ul", { staticClass: "contact-tab" }, [
                    _c(
                      "li",
                      {
                        class: { active: _vm.contactComponent === 1 },
                        on: {
                          mouseenter: function ($event) {
                            _vm.contactComponent = 2
                          },
                        },
                      },
                      [_vm._v("在线咨询")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "contact-content" }, [
                    _c("div", { staticClass: "wx" }, [
                      _c("i", { staticClass: "iconfont iconzixun1" }),
                    ]),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "consult",
                        attrs: {
                          href: "http://q.url.cn/s/Vbkup6m?_type=wpa",
                          target: "_blank",
                        },
                      },
                      [_vm._v("在线咨询")]
                    ),
                  ]),
                ]),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import Vue from 'vue'
import App from './App'
import http from 'src/utils/http'
import 'sanitize.css'
import 'src/styles/index.scss'
import loginMixin from 'src/mixins/login'
import 'element-ui/lib/theme-chalk/index.css'
import 'swiper/swiper-bundle.min.css'

Vue.use(http)

new Vue({
  el: '#app',
  mixins: [loginMixin],
  render: h => h(App),
})
<template>
    <div class="activity-card-container">
        <h4>
            <span class="title">{{title}}</span>
            <slot name="side"></slot>
        </h4>
        <slot></slot>
    </div>
</template>

<script>
  export default {
    name: "activity-card",
    props: ['title', 'time'],
  }
</script>

<style scoped lang="scss">
    .activity-card-container {
        width: 344px;
        border: 1px solid #EFF1F4;
        padding: 16px;

        h4 {
            display: flex;
            justify-content: space-between;
            padding-bottom: 16px;
            margin-bottom: 16px;
            border-bottom: 1px solid #EFF1F4;
        }

        .title {
            color: #2F3742;
            font-weight: 600;
            font-size: 16px;
        }
    }
</style>
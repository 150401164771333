<template>
  <div class="user">
    <div class="avatar"><img :src="userInfo.avatar_file" alt="" /></div>
    <div class="name">{{ userInfo.user_name }}</div>
    <ul class="info">
      <li>
        <a href="/personal?tab=collection">
          <div>{{ userCard.collection }}</div>
          <span>收藏</span>
        </a>
      </li>
      <li>
        <a href="/personal">
          <div>{{ userCard.article }}</div>
          <span>帖子</span>
        </a>
      </li>
      <li>
        <a href="/credit?tab=MyCredit">
          <div>{{ userCard.valid_integral }}</div>
          <span>积分</span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'card',
  props: ['userInfo'],
  data() {
    return {
      userCard: {},
    }
  },
  created() {
    this.getUserCard()
  },
  methods: {
    getUserCard() {
      this.$http.get('/userCard').then(res => {
        const { code, msg, data } = res.data
        if (code === 200) {
          this.userCard = data
          this.$emit('update:isSign', data.isSign)
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.user {
  .avatar {
    text-align: center;
    margin-bottom: 10px;

    img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }
  }

  .name {
    margin-bottom: 30px;
    color: #687583;
    font-size: 14px;
    text-align: center;
  }

  .info {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;

    li {
      padding: 0 30px;

      &:nth-child(2) {
        border-left: 1px solid #ededed;
        border-right: 1px solid #ededed;
      }

      &:hover {
        div {
          color: #09f;
        }
      }
    }

    div {
      margin-bottom: 10px;
      text-align: center;
    }

    span {
      color: #687583;
    }

    a {
      color: inherit;
      text-decoration: inherit;
    }
  }
}
</style>

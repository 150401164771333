const loginMixin = {
  data() {
    return {
      isLogin: false,
      userInfo: {},
    }
  },
  created() {
    if (!this.isLogin && !this.$root.isLogin) {
      this.getLoginStatus()
    }
  },
  methods: {
    getLoginStatus() {
      this.$http.post(`${API}/get_login_info`)
        .then(res => {
          if (res.data.errno !== 4030 && res.data.errno !== 4040) {
            this.isLogin = true
            this.userInfo = res.data
          } else {
            this.isLogin = false
          }
        })
    },
  },
}

export default loginMixin
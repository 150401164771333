<template>
    <div class="container">
        <CommonHeader
                :isLogin="$root.isLogin"
                :user="$root.userInfo"
                :shopCartList="shopCartList"
                :defaultHotWord="defaultHotWord"
        />
        <div class="content">
            <slot></slot>
        </div>
        <footer>
            <CommonFooter/>
        </footer>
    </div>
</template>

<script>
  import CommonHeader from 'common/common-header'
  import CommonFooter from 'common/common-footer'

  export default {
    name: "index",
    components: {
      CommonHeader,
      CommonFooter,
    },
    data() {
      return {
        shopCartList: [],
        searchHotWords: [],
        defaultHotWord: '',
      }
    },
    created() {
      this.getShoppingCart()
    },
    methods: {
      getShoppingCart() {
        this.$http.get(`${FAST}/web/home/shopping_cart`)
          .then(res => {
            const {data, code} = res.data
            if (code === 200) {
              this.shopCartList = data.list
            }
          })
      },
    },
  }
</script>

<style lang="scss">
    html, body {
        height: 100%;
    }

    $footerHeight: 304px;

    body {
        > .container {
            height: 100%;

            > .content {
                min-height: 100%;
                padding-bottom: 4px;
            }

            > footer {
              height: auto;
                // height: $footerHeight;
                // margin-top: -$footerHeight;
            }
        }
    }
</style>
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "common-footer" } },
    [
      !_vm.isMobile
        ? [
            _c("div", { staticClass: "foot pc-footer" }, [
              _c("div", { staticClass: "mainContainer" }, [
                _vm._m(0),
                _vm._v(" "),
                _c("div", { staticClass: "foot-line fl" }),
                _vm._v(" "),
                _c("div", { staticClass: "foot-center fl" }, [
                  _c("ul", { staticClass: "foot-list" }, [
                    _c(
                      "li",
                      [
                        _c("div", { staticClass: "foot-center-title" }, [
                          _vm._v("快速导航"),
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.nav, function (it, i) {
                          return _c(
                            "a",
                            {
                              key: i,
                              staticClass: "footicon",
                              attrs: { href: it.link, target: "_blank" },
                            },
                            [_vm._v(_vm._s(it.name))]
                          )
                        }),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _vm._m(1),
                    _vm._v(" "),
                    _vm._m(2),
                    _vm._v(" "),
                    _vm._m(3),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _vm._m(4),
            ]),
          ]
        : [
            _c("div", { staticClass: "common-footer-phone" }, [
              _c("div", { staticClass: "common-footer-container" }, [
                _c("div", { staticClass: "common-footer-top" }, [
                  _vm._m(5),
                  _vm._v(" "),
                  _c("div", { staticClass: "footer-text" }, [
                    _vm._v("智能时代在线职教平台"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "footer-text follow" }, [
                    _c("span", [_vm._v("关注我们：")]),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "weibo-link",
                        attrs: {
                          href: "http://weibo.com/askjulyedu",
                          target: "_blank",
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "footer_icon-img footer_icon-wb-img",
                          attrs: { src: _vm.weiboImg },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "weixin-link2",
                        attrs: { href: "javascript:;" },
                      },
                      [
                        _c("img", {
                          staticClass: "footer_icon-img footer_icon-wx-img",
                          attrs: { src: _vm.weixinImg },
                        }),
                        _vm._v(" "),
                        _c("img", {
                          staticClass: "weixin_code2",
                          attrs: {
                            src: "https://cdn.julyedu.com/tinypng-common/windows_code.png",
                          },
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _vm._m(6),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "common-footer-bottom" }, [
                  _c("div", { staticClass: "down2" }, [
                    _vm._m(7),
                    _vm._v(" "),
                    _c("ul", { staticClass: "foot-applist1" }, [
                      _c("li", [
                        _c("img", {
                          staticClass: "before-icon",
                          attrs: { src: _vm.iPhoneIcon, alt: "", srcset: "" },
                        }),
                        _vm._v(" "),
                        _c("span", [_vm._v("iPhone")]),
                        _vm._v(" "),
                        _c("img", {
                          staticClass: "appCode2",
                          attrs: {
                            src: "https://julyedu-img.oss-cn-beijing.aliyuncs.com/ncnncnnckkrr.png",
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("img", {
                          staticClass: "before-icon",
                          attrs: { src: _vm.AndroidIcon, alt: "", srcset: "" },
                        }),
                        _vm._v(" "),
                        _c("span", [_vm._v("Android")]),
                        _vm._v(" "),
                        _c("img", {
                          staticClass: "appCode2",
                          attrs: {
                            src: "https://julyedu-img.oss-cn-beijing.aliyuncs.com/sdxwsxscscsdcdcsdcdcrewsc.png",
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("img", {
                          staticClass: "before-icon",
                          attrs: { src: _vm.iPhoneIcon, alt: "", srcset: "" },
                        }),
                        _vm._v(" "),
                        _c("span", [_vm._v("iPad")]),
                        _vm._v(" "),
                        _c("img", {
                          staticClass: "appCode2",
                          attrs: {
                            src: "https://julyedu-img.oss-cn-beijing.aliyuncs.com/cnncjcjrjcncnmc%20m%20n.png",
                          },
                        }),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "contect" }, [
                    _vm._m(8),
                    _vm._v(" "),
                    _c("p", [
                      _c("img", {
                        staticClass: "before-icon",
                        attrs: { src: _vm.dianhuaIcon, alt: "", srcset: "" },
                      }),
                      _vm._v(" "),
                      _c("span", [_vm._v("18910848502")]),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _c("img", {
                        staticClass: "before-icon",
                        attrs: { src: _vm.youxiangIcon, alt: "", srcset: "" },
                      }),
                      _vm._v(" "),
                      _c("span", [_vm._v("support@julyedu.com")]),
                    ]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _vm._m(9),
            ]),
          ],
      _vm._v(" "),
      _vm.showGlobalEntry && !_vm.isMobile
        ? _c("div", { staticClass: "year19-index" }, [
            _c("i", {
              staticClass: "iconfont icon-RectangleCopy",
              on: { click: _vm.closeEntry },
            }),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "year19-index",
                attrs: { href: _vm.jumpUrl, target: "_blank" },
              },
              [_c("img", { attrs: { src: _vm.imagePath, alt: "" } })]
            ),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "foot-left fl" }, [
      _c("h1", { staticClass: "foot-logo" }, [
        _c("a", { attrs: { href: "/" } }, [
          _c("img", {
            attrs: {
              src: "https://julyedu-img-public.oss-cn-beijing.aliyuncs.com/Public/Image/logo.png",
            },
          }),
        ]),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "foot-title" }, [_vm._v("智能时代在线职教平台")]),
      _vm._v(" "),
      _c("div", { staticClass: "foot-our" }, [
        _c("span", { staticClass: "follow" }, [_vm._v("关注我们：")]),
        _vm._v(" "),
        _c(
          "a",
          { staticClass: "weixin-link", attrs: { href: "javascript:;" } },
          [
            _c("span", { staticClass: "footer_icon footer_icon-wxbg" }),
            _vm._v(" "),
            _c("img", {
              staticClass: "weixin_code",
              attrs: {
                src: "https://julyedu-cdn.oss-cn-beijing.aliyuncs.com/tinypng-common/windows_code.png",
              },
            }),
          ]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "weibo-link",
            attrs: { href: "http://weibo.com/askjulyedu", target: "_blank" },
          },
          [_c("span", { staticClass: "footer_icon footer_icon-wbbg" })]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "foot-five" }, [
        _c(
          "a",
          {
            staticClass: "footicon",
            attrs: { href: "/help/index/about", target: "_blank" },
          },
          [_vm._v("关于我们")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "foot-five-line" }),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "footicon-right",
            attrs: { href: "/help/index/join", target: "_blank" },
          },
          [_vm._v("加入我们")]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("div", { staticClass: "foot-center-title" }, [_vm._v("合作伙伴")]),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "footicon",
          attrs: { href: "https://blog.csdn.net/v_JULY_v", target: "_blank" },
        },
        [_vm._v("CSDN\n              ")]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "footicon",
          attrs: {
            href: "https://tianchi.aliyun.com/course/?fromjulyedu",
            target: "_blank",
          },
        },
        [_vm._v("阿里云天池\n              ")]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "footicon",
          attrs: {
            href: "http://neuhub.jd.com/promotion/recommend",
            target: "_blank",
          },
        },
        [_vm._v("京东AI开放平台\n              ")]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "footicon",
          attrs: {
            href: "https://study.163.com/courses-search?keyword=%E4%B8%83%E6%9C%88%E5%9C%A8%E7%BA%BF#/?scht=30",
            target: "_blank",
          },
        },
        [_vm._v("网易云课堂\n              ")]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "footicon",
          attrs: { href: "https://www.epubit.com", target: "_blank" },
        },
        [_vm._v("人邮异步社区")]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "footicon",
          attrs: {
            href: "https://www.aidaxue.com/course/search?search=%E4%B8%83%E6%9C%88%E5%9C%A8%E7%BA%BF",
            target: "_blank",
          },
        },
        [_vm._v("讯飞AI大学")]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "footicon",
          attrs: { href: "https://mofanpy.com/", target: "_blank" },
        },
        [_vm._v("莫烦Python")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "foot-applist" }, [
      _c("div", { staticClass: "foot-center-title" }, [_vm._v("客户端下载")]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "footicon",
          staticStyle: { position: "relative", top: "-4px" },
        },
        [
          _c("img", {
            attrs: {
              src: "https://julyedu-img-public.oss-cn-beijing.aliyuncs.com/Public/Image/ipad.png",
            },
          }),
          _vm._v(" "),
          _c("span", [_vm._v("iPhone")]),
          _vm._v(" "),
          _c("img", {
            staticClass: "appCode",
            attrs: {
              src: "https://julyedu-cdn.oss-cn-beijing.aliyuncs.com/tinypng-common/footer_windows_iphone.png",
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "footicon",
          staticStyle: { position: "relative", top: "-4px" },
        },
        [
          _c("img", {
            attrs: {
              src: "https://julyedu-img-public.oss-cn-beijing.aliyuncs.com/Public/Image/Android.png",
            },
          }),
          _vm._v(" "),
          _c("span", [_vm._v("Android")]),
          _vm._v(" "),
          _c("img", {
            staticClass: "appCode",
            attrs: {
              src: "https://julyedu-cdn.oss-cn-beijing.aliyuncs.com/tinypng-common/footer_windows_android.png",
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "footicon",
          staticStyle: { position: "relative", top: "-4px" },
        },
        [
          _c("img", {
            attrs: {
              src: "https://julyedu-img-public.oss-cn-beijing.aliyuncs.com/Public/Image/ipad.png",
            },
          }),
          _vm._v(" "),
          _c("span", [_vm._v("iPad")]),
          _vm._v(" "),
          _c("img", {
            staticClass: "appCode",
            attrs: {
              src: "https://julyedu-cdn.oss-cn-beijing.aliyuncs.com/tinypng-common/footer_windows_iphone.png",
            },
          }),
        ]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("div", { staticClass: "foot-center-title" }, [_vm._v("联系我们")]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "footicon",
          staticStyle: { position: "relative", top: "-4px" },
        },
        [
          _c("img", {
            attrs: {
              src: "https://julyedu-img-public.oss-cn-beijing.aliyuncs.com/Public/Image/tel.png",
            },
          }),
          _vm._v(" "),
          _c("span", [_vm._v("18910848502")]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "footicon",
          staticStyle: { position: "relative", top: "-4px" },
        },
        [
          _c("img", {
            attrs: {
              src: "https://julyedu-img-public.oss-cn-beijing.aliyuncs.com/Public/Image/email.png",
            },
          }),
          _vm._v(" "),
          _c("span", [_vm._v("support@julyedu.com")]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "foot-wrap" }, [
      _vm._v(
        "\n        七月在线科技©2015-2022 · ICP证：京B2-20180320 ·\n        "
      ),
      _c(
        "a",
        {
          staticStyle: { color: "#999" },
          attrs: { href: "http://beian.miit.gov.cn/", target: "_blank" },
        },
        [_vm._v("京ICP备15036064号-2")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      { staticClass: "index-a", attrs: { href: "https://www.julyedu.com" } },
      [
        _c("img", {
          staticStyle: { width: "100%", height: "100%", display: "block" },
          attrs: {
            src: "https://julyedu-img-public.oss-cn-beijing.aliyuncs.com/Public/Image/logo.png",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "foot-our2" }, [
      _c(
        "a",
        {
          staticClass: "footicon2",
          attrs: {
            href: "https://www.julyedu.com/help/index/about",
            target: "_blank",
          },
        },
        [_vm._v("关于我们")]
      ),
      _vm._v(" "),
      _c("i", { staticClass: "foot-icon" }),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "footicon-right",
          attrs: {
            href: "https://www.julyedu.com/help/index/join",
            target: "_blank",
          },
        },
        [_vm._v("加入我们")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "foot-title2" }, [
      _c("span", [_vm._v("客户端下载")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "foot-title2" }, [
      _c("span", [_vm._v("联系我们")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "footer-foot" }, [
      _c("p", { staticStyle: { "text-align": "center" } }, [
        _vm._v("\n          七月在线科技©2022 ·\n          "),
        _c(
          "a",
          {
            staticStyle: { color: "rgb(153, 153, 153)" },
            attrs: { target: "_blank", href: "http://beian.miit.gov.cn/" },
          },
          [_vm._v("\n            京ICP备15036064号-2\n          ")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div id="common-footer">
    <template v-if="!isMobile">
      <div class="foot pc-footer">
        <div class="mainContainer">
          <div class="foot-left fl">
            <h1 class="foot-logo">
              <a href="/">
                <img
                  src="https://julyedu-img-public.oss-cn-beijing.aliyuncs.com/Public/Image/logo.png"
                />
              </a>
            </h1>
            <p class="foot-title">智能时代在线职教平台</p>
            <div class="foot-our">
              <span class="follow">关注我们：</span>

              <a href="javascript:;" class="weixin-link">
                <!--<img src="__OSS__/Public/Image/home/icon_weixin.png" class="footer_icon">-->
                <span class="footer_icon footer_icon-wxbg"></span>
                <img
                  class="weixin_code"
                  src="https://julyedu-cdn.oss-cn-beijing.aliyuncs.com/tinypng-common/windows_code.png"
                />
              </a>
              <a href="http://weibo.com/askjulyedu" target="_blank" class="weibo-link">
                <!--<img src="__OSS__/Public/Image/home/icon_weibo.png" class="footer_icon">-->
                <span class="footer_icon footer_icon-wbbg"></span>
              </a>
            </div>
            <div class="foot-five">
              <a class="footicon" href="/help/index/about" target="_blank">关于我们</a>
              <div class="foot-five-line"></div>
              <a class="footicon-right" href="/help/index/join" target="_blank"
                >加入我们</a
              >
            </div>
          </div>
          <div class="foot-line fl"></div>
          <div class="foot-center fl">
            <ul class="foot-list">
              <li>
                <div class="foot-center-title">快速导航</div>
                <a
                  class="footicon"
                  v-for="(it, i) in nav"
                  :key="i"
                  :href="it.link"
                  target="_blank"
                  >{{ it.name }}</a
                >
              </li>
              <li>
                <div class="foot-center-title">合作伙伴</div>
                <a class="footicon" href="https://blog.csdn.net/v_JULY_v" target="_blank"
                  >CSDN
                </a>
                <a
                  class="footicon"
                  href="https://tianchi.aliyun.com/course/?fromjulyedu"
                  target="_blank"
                  >阿里云天池
                </a>
                <a
                  class="footicon"
                  href="http://neuhub.jd.com/promotion/recommend"
                  target="_blank"
                  >京东AI开放平台
                </a>
                <a
                  class="footicon"
                  href="https://study.163.com/courses-search?keyword=%E4%B8%83%E6%9C%88%E5%9C%A8%E7%BA%BF#/?scht=30"
                  target="_blank"
                  >网易云课堂
                </a>
                <a class="footicon" href="https://www.epubit.com" target="_blank"
                  >人邮异步社区</a
                >
                <a
                  class="footicon"
                  href="https://www.aidaxue.com/course/search?search=%E4%B8%83%E6%9C%88%E5%9C%A8%E7%BA%BF"
                  target="_blank"
                  >讯飞AI大学</a
                >
                <a class="footicon" href="https://mofanpy.com/" target="_blank"
                  >莫烦Python</a
                >
              </li>
              <li class="foot-applist">
                <div class="foot-center-title">客户端下载</div>
                <div class="footicon" style="position: relative; top: -4px">
                  <img
                    src="https://julyedu-img-public.oss-cn-beijing.aliyuncs.com/Public/Image/ipad.png"
                  />
                  <span>iPhone</span>
                  <img
                    class="appCode"
                    src="https://julyedu-cdn.oss-cn-beijing.aliyuncs.com/tinypng-common/footer_windows_iphone.png"
                  />
                </div>

                <div class="footicon" style="position: relative; top: -4px">
                  <img
                    src="https://julyedu-img-public.oss-cn-beijing.aliyuncs.com/Public/Image/Android.png"
                  />
                  <span>Android</span>
                  <img
                    class="appCode"
                    src="https://julyedu-cdn.oss-cn-beijing.aliyuncs.com/tinypng-common/footer_windows_android.png"
                  />
                </div>

                <div class="footicon" style="position: relative; top: -4px">
                  <img
                    src="https://julyedu-img-public.oss-cn-beijing.aliyuncs.com/Public/Image/ipad.png"
                  />
                  <span>iPad</span>
                  <img
                    class="appCode"
                    src="https://julyedu-cdn.oss-cn-beijing.aliyuncs.com/tinypng-common/footer_windows_iphone.png"
                  />
                </div>
              </li>
              <li>
                <div class="foot-center-title">联系我们</div>
                <div class="footicon" style="position: relative; top: -4px">
                  <img
                    src="https://julyedu-img-public.oss-cn-beijing.aliyuncs.com/Public/Image/tel.png"
                  />
                  <span>18910848502</span>
                </div>
                <div class="footicon" style="position: relative; top: -4px">
                  <img
                    src="https://julyedu-img-public.oss-cn-beijing.aliyuncs.com/Public/Image/email.png"
                  />
                  <span>support@julyedu.com</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="foot-wrap">
          七月在线科技©2015-2022 · ICP证：京B2-20180320 ·
          <a href="http://beian.miit.gov.cn/" style="color: #999" target="_blank"
            >京ICP备15036064号-2</a
          >
        </div>
      </div>
    </template>
    <template v-else>
      <div class="common-footer-phone">
        <div class="common-footer-container">
          <div class="common-footer-top">
            <a href="https://www.julyedu.com" class="index-a">
              <img
                src="https://julyedu-img-public.oss-cn-beijing.aliyuncs.com/Public/Image/logo.png"
                style="width: 100%; height: 100%; display: block"
            /></a>
            <div class="footer-text">智能时代在线职教平台</div>
            <div class="footer-text follow">
              <span>关注我们：</span>
              <a href="http://weibo.com/askjulyedu" target="_blank" class="weibo-link"
                ><img :src="weiboImg" class="footer_icon-img footer_icon-wb-img" />
              </a>
              <a href="javascript:;" class="weixin-link2"
                ><img :src="weixinImg" class="footer_icon-img footer_icon-wx-img" />
                <img
                  src="https://cdn.julyedu.com/tinypng-common/windows_code.png"
                  class="weixin_code2"
              /></a>
            </div>
            <div class="foot-our2">
              <a
                href="https://www.julyedu.com/help/index/about"
                target="_blank"
                class="footicon2"
                >关于我们</a
              >
              <i class="foot-icon"></i>
              <a
                href="https://www.julyedu.com/help/index/join"
                target="_blank"
                class="footicon-right"
                >加入我们</a
              >
            </div>
          </div>
          <div class="common-footer-bottom">
            <div class="down2">
              <div class="foot-title2"><span>客户端下载</span></div>
              <ul class="foot-applist1">
                <li>
                  <img class="before-icon" :src="iPhoneIcon" alt="" srcset="" />
                  <span>iPhone</span>
                  <img
                    src="https://julyedu-img.oss-cn-beijing.aliyuncs.com/ncnncnnckkrr.png"
                    class="appCode2"
                  />
                </li>
                <li>
                  <img class="before-icon" :src="AndroidIcon" alt="" srcset="" />
                  <span>Android</span>
                  <img
                    src="https://julyedu-img.oss-cn-beijing.aliyuncs.com/sdxwsxscscsdcdcsdcdcrewsc.png"
                    class="appCode2"
                  />
                </li>
                <li>
                  <img class="before-icon" :src="iPhoneIcon" alt="" srcset="" />
                  <span>iPad</span>
                  <img
                    src="https://julyedu-img.oss-cn-beijing.aliyuncs.com/cnncjcjrjcncnmc%20m%20n.png"
                    class="appCode2"
                  />
                </li>
              </ul>
            </div>
            <div class="contect">
              <div class="foot-title2"><span>联系我们</span></div>

              <p>
                <img class="before-icon" :src="dianhuaIcon" alt="" srcset="" />
                <span>18910848502</span>
              </p>
              <p>
                <img class="before-icon" :src="youxiangIcon" alt="" srcset="" />
                <span>support@julyedu.com</span>
              </p>
            </div>
          </div>
        </div>
        <div class="footer-foot">
          <p style="text-align: center">
            七月在线科技©2022 ·
            <a
              target="_blank"
              href="http://beian.miit.gov.cn/"
              style="color: rgb(153, 153, 153)"
            >
              京ICP备15036064号-2
            </a>
          </p>
        </div>
        <!---->
      </div>
    </template>

    <!--宝箱活动全站入口-->
    <div class="year19-index" v-if="showGlobalEntry&&!isMobile">
      <i class="iconfont icon-RectangleCopy" @click="closeEntry"></i>
      <a class="year19-index" :href="jumpUrl" target="_blank">
        <img :src="imagePath" alt="" />
      </a>
    </div>
  </div>
</template>

<script>
import { isMobile } from "mobile-device-detect";
import iPhoneIcon from "../../assets/image/iPhoneIcon.png";
import AndroidIcon from "../../assets/image/AndroidIcon.png";
import weiboImg from "../../assets/image/xinlangweibo3.png";
import weixinImg from "../../assets/image/weixin3.png";
import youxiangIcon from "../../assets/image/youxiang3.png";
import dianhuaIcon from "../../assets/image/dianhuatianchong3.png";
export default {
  name: "common-footer",
  data() {
    return {
      jumpUrl: "",
      imagePath: "",
      isShowActivityEntry: 0,
      lastEntryCloseTime: localStorage.getItem("globalEntryClosedTime"),
      EDU: window.EDU,
      isMobile: isMobile,
      iPhoneIcon,
      AndroidIcon,
      weiboImg,
      weixinImg,
      youxiangIcon,
      dianhuaIcon,
      nav: [
        {
          name: "首页",
          link: "/",
        },
        {
          name: "精品课程",
          link: "/category/index",
        },
        {
          name: "免费课程",
          link: "/category/index?type=free#free",
        },
        {
          name: "面试题库",
          link: "/questions/written",
        },
        {
          name: "面经/社区",
          link: window.ASK,
        },
        {
          name: "企业内训",
          link: "/active/qiye",
        },
      ]
    };
  },
  created() {
    this.getStage();
  },
  computed: {
    showGlobalEntry() {
      if (this.lastEntryCloseTime) {
        const today = new Date();
        const lastCloseTime = new Date(parseInt(this.lastEntryCloseTime));
        return (
          this.isShowActivityEntry &&
          (today.getFullYear() > lastCloseTime.getFullYear() ||
            today.getMonth() > lastCloseTime.getMonth() ||
            today.getDate() > lastCloseTime.getDate())
        );
      }
      return this.isShowActivityEntry;
    },
  },
  methods: {
    // 获取宝箱阶段
    getStage() {
      this.$http.get(`${FAST}/sys/anniversary/floating`).then((res) => {
        const { code, data, msg } = res.data;
        if (code === 200) {
          this.isShowActivityEntry = data.is_show;
          this.jumpUrl = data.jump_url;
          this.imagePath = data.image_path;
        }
      });
    },
    closeEntry() {
      const now = Date.now().toString();
      localStorage.setItem("globalEntryClosedTime", now);
      this.lastEntryCloseTime = now;
    },
  },
};
</script>
<style lang="scss">
#common-footer {
  .warpper {
    margin-bottom: 0 !important;
  }

  li {
    list-style: none;
  }

  .mainContainer {
    width: 1200px;
    min-width: 1200px;
    margin: 0 auto;
    padding-bottom: 28px;
    padding-left: 111px;
    &:after {
      content: "";
      display: block;
      clear: both;
    }
  }

  .fixedRight li {
    visibility: visible !important;
    top: 0 !important;
    left: 0 !important;
  }

  .fl {
    float: left !important;
  }

  .fr {
    float: right !important;
  }

  .foot {
    background-color: #eee;
    color: #eee;
    width: 100%;
    min-width: 1200px;
    padding-top: 37px;
    font-size: 14px;
    line-height: 30px;
    margin-bottom: 0 !important;
  }

  .foot-left {
    float: left;
  }

  .foot-logo {
    width: 160px;
    height: 44px;
    margin: 0 !important;
    padding: 0 !important;
    border-bottom: none !important;
  }

  .foot-logo img {
    width: 100%;
    height: 100%;
  }

  .foot-title {
    font-size: 16px;
    color: #333333;
    letter-spacing: 0px;
    font-weight: 500;
    // padding-left: 9px;
    margin-top: 16px;
    margin-right: 84px;
    line-height: 1;
  }

  .foot-our {
    position: relative;
    margin-top: 16px;
    display: flex;
    align-items: center;
  }

  .footicon-right {
    color: #97a1af;
    font-size: 13px;
    // padding-left: 10px;
  }

  .foot-list {
    padding: 0;
    display: flex;
  }

  .foot-list li {
    margin-right: 80px;
    .foot-center-title {
      font-family: PingFangSC-Medium;
      font-size: 14px;
      color: #333333;
      letter-spacing: 0.44px;
      text-align: justify;
      line-height: 16px;
      font-weight: 500;
      margin-bottom: 32px;
    }
    .footicon {
      font-size: 12px;
      display: block;
      color: #999;
      margin-bottom: 8px;
      line-height: 1;
      text-decoration: none;
      position: relative;
      display: flex;
      align-items: center;
      &:hover .appCode {
        visibility: visible;
      }

      .appCode {
        position: absolute;
        top: -180px;
        left: -30px;
        visibility: hidden;
      }
    }
  }

  .foot-icon {
    font-style: normal;
    color: rgba(255, 255, 255, 0.6);
    font-size: 12px;
    margin-left: 4px;
    margin-right: 4px;
  }

  .foot-downtitle {
    float: left;
    font-size: 14px;
    color: #97a1af;
  }
  .foot-line {
    width: 1px;
    height: 120px;
    background: #999999;
    margin-top: 20px;
  }
  .foot-center {
    width: 810px;
    padding-left: 80px;
  }

  .weibo-link {
    display: inline-block;
    position: relative;
    margin-right: 10px;
    height: 20px;
  }

  .footer_icon {
    width: 20px;
    height: 20px;
    background-size: 100%;
    // padding: 4px;
    // background: #808080;
    // vertical-align: middle;
    display: inline-block;
    border-radius: 50%;
  }

  .weixin-link {
    position: relative;
    height: 20px;
    margin-right: 24px;
    margin-left: 10px;
  }

  .weixin-link:hover .weixin_code {
    visibility: visible;
  }

  .weixin_code {
    position: absolute;
    top: -226px;
    left: -75px;
    visibility: hidden;
  }

  .foot-wrap {
    clear: both;
    height: 48px;
    line-height: 48px;
    background: #e5e5e5;
    font-size: 12px;
    color: #999;
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }

  .follow {
    font-size: 14px;
    color: #333333;
    letter-spacing: 0.88px;
    text-align: justify;
    font-weight: 500;
    // padding-left: 9px;
    line-height: 1;
  }

  .cooperation {
    font-size: 13px;
    margin-bottom: 50px;
    color: #6b798e;
  }

  .cooperation a {
    color: #6b798e;
  }

  .foot-right p {
    font-size: 13px;
    color: #6b798e;
    height: 15px;
    margin-bottom: 12px;
    line-height: 15px;
  }

  .foot-right-title {
    font-size: 15px;
    color: #97a1af;
    display: inline-block;
    height: 15px;
    line-height: 15px;
    margin-bottom: 15px;
  }

  .footer_icon-wbbg {
    background-image: url("https://julyedu-img-public.oss-cn-beijing.aliyuncs.com/Public/Image/wibo.png");
    background-repeat: no-repeat;
  }

  .footer_icon-wxbg {
    background-image: url("https://julyedu-img-public.oss-cn-beijing.aliyuncs.com/Public/Image/weixin.png");
    background-repeat: no-repeat;
  }

  .footer_icon-wbbg:hover {
    background-image: url("https://julyedu-cdn.oss-cn-beijing.aliyuncs.com/newIndex/footer/wbgl.png");
    background-repeat: no-repeat;
  }

  .footer_icon-wxbg:hover {
    background-image: url("https://julyedu-cdn.oss-cn-beijing.aliyuncs.com/newIndex/footer/wxgl.png");
    background-repeat: no-repeat;
  }

  .year19-index {
    position: fixed;
    top: 27.5%;
    right: 26px;
    z-index: 999;
    
    .iconfont {
      position: absolute;
      right: 0;
      top: -38px;
      font-size: 38px;
      cursor: pointer;
    }
  }

  .year19-index img {
    width: 100%;
    height: 100%;
  }
  .foot-five{
    // padding-left: 9px;
    line-height:1;
    margin-top: 14px;
    display: flex;
    height: 20px;
    align-items: center;
    justify-content: space-between;
    align-items: center;
    width: 160px;
    a{
      color:#666;
    }
    .foot-five-line{
      height: 12px;
      width:2px;
      background-color:#D8D8D8;
      margin:1px 19px;
    }
  }
  .common-footer-phone {
    background: #eeeeee;
    .year19-index{
      display:none;
    }
    .common-footer-container {
      display: flex;
      padding-top: 16px;
      padding-bottom: 12px;
      .common-footer-top {
        width: 156px;
        padding-left: 16px;

        .index-a {
          display: block;
          width: 120px;
          height: 33px;
          img {
            display: block;
          }
        }
        .footer-text {
          margin-top: 8px;
          font-size: 12px;
          color: #333;
          line-height: 16px;
          &.follow {
            display: flex;
            align-items: center;
            line-height: 17px;
            a {
              display: block;
              width: 17px;
              height: 14px;
              &:hover {
                .weixin_code2 {
                  visibility: visible;
                }
              }
            }
            .footer_icon-img {
              width: 100%;
              height: 100%;
              display: block;
            }
            .footer_icon-wbbg {
              background-image: url("https://cdn.julyedu.com/newIndex/footer/wbmr.png");
              background-repeat: no-repeat;
            }

            .footer_icon-wxbg {
              background-image: url("https://cdn.julyedu.com/newIndex/footer/wxmr.png");
              background-repeat: no-repeat;
            }

            .footer_icon-wbbg:hover {
              background-image: url("https://cdn.julyedu.com/newIndex/footer/wbgl.png");
              background-repeat: no-repeat;
            }

            .footer_icon-wxbg:hover {
              background-image: url("https://cdn.julyedu.com/newIndex/footer/wxgl.png");
              background-repeat: no-repeat;
            }
            .footer_icon {
              width: 17px;
              height: 14px;
              vertical-align: middle;
              display: inline-block;
              border-radius: 50%;
              background-size: 100% 100%;
            }
            .weixin_code2 {
              width: 70px;
              height: 90px;
              position: absolute;
              top: -89px;
              left: -38px;
              visibility: hidden;
            }
          }
        }
        .foot-our2 {
          display: flex;
          align-items: center;
          margin-top: 16px;

          a {
            text-decoration: none;
            color: #666;
            font-size: 11px;
            padding-left: 0px;
          }
          .foot-icon {
            display: block;
            margin: 0 12px;
            height: 10px;
            width: 1px;
            background: #d8d8d8;
          }
        }
      }
      .common-footer-bottom {
        width: 218px;
        padding-left: 20px;

        border-left: 1px solid #999;
        .foot-title2 {
          color: #333;
          font-size: 12px;
          margin-bottom: 10px;
          line-height: 14px;
          letter-spacing: 0.38px;
        }
        .down2 {
          margin-bottom: 16px;
          .foot-applist1 {
            display: flex;
            align-items: center;
            font-size: 10px;
            li {
              list-style: none;
              margin-left: 12px;
              display: flex;
              align-items: center;
              color: #999;
              &:first-child {
                margin-left: 0;
              }
              &:hover .appCode2 {
                visibility: visible;
              }
              .before-icon {
                display: block;
                width: auto;
                height: 14px;
                margin-right: 8px;
              }
              .appCode2 {
                width: 60px;
                height: 70px;
                position: absolute;
                /* width: 60px; */
                /* bottom: 0px; */
                margin-top: 30px;
                left: -5px;
                visibility: hidden;
                z-index: 9999;
              }
            }
          }
        }
        .contect {
          p {
            display: flex;
            font-size: 10px;
            line-height: 14px;
            color: #666;
            &:last-child {
              margin-top: 8px;
            }
            img.before-icon {
              margin-right: 8px;
              width: auto;
              height: 12px;
            }
          }
        }
      }
    }
    .footer-foot {
      background: #e5e5e5;
      height: 34px;
      line-height: 34px;
      text-align: center;
      p {
        font-size: 10px;
        color: #999;
      }
    }
  }
}

</style>

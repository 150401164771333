var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "btns" },
    [
      _c("button", { on: { click: _vm.publish } }, [_vm._v("发帖")]),
      _vm._v(" "),
      !_vm.isSign
        ? _c("button", { on: { click: _vm.sign } }, [_vm._v("签到")])
        : _c("button", { attrs: { disabled: "" } }, [_vm._v("已签到")]),
      _vm._v(" "),
      _c(
        "transition",
        {
          attrs: {
            name: "sign",
            "enter-active-class":
              "animate__animated animate__slideInUp animate__faster",
            "leave-active-class":
              "animate__animated animate__slideOutDown animate__faster",
          },
        },
        [
          _vm.isShowAddedCredit
            ? _c("div", { staticClass: "signed" }, [
                _c("span", [_vm._v("+" + _vm._s(_vm.score))]),
                _vm._v("积分"),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _vm.isShowGuidance
        ? _c("div", { staticClass: "tip sign-success" }, [
            _c("div", { staticClass: "title" }, [_vm._v("签到成功")]),
            _vm._v(" "),
            _vm._m(0),
            _vm._v(" "),
            _c(
              "button",
              {
                on: {
                  click: function ($event) {
                    _vm.isShowGuidance = false
                  },
                },
              },
              [_vm._v("我知道了")]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "info" }, [
              _vm._v("点击积分即可进入【积分中心】"),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isShowTip
        ? _c("div", { staticClass: "tip" }, [
            _vm._m(1),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "close",
                on: {
                  click: function ($event) {
                    _vm.isShowTip = false
                  },
                },
              },
              [_vm._v("我知道了")]
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tip-content" }, [
      _vm._v("\n            完成"),
      _c("span", [_vm._v("【积分中心】")]),
      _vm._v("的各种任务"),
      _c("br"),
      _vm._v(" 可以获得更多积分哦～\n        "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tip-content" }, [
      _vm._v("每天登录后都可以在这签到"),
      _c("br"),
      _vm._v(" 领取积分"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="user-card">
    <User :userInfo="$root.userInfo" :isSign.sync="isSign" ref="user"/>
    <Buttons :isShowTip="isShowTip" :isSign.sync="isSign" :isShowPopup="isShowPopup" />
  </div>
</template>

<script>
import User from './user'
import Buttons from './buttons'

export default {
  name: 'userCardAll',
  props: ['isShowTip', 'isShowPopup'],
  data() {
    return {
      isSign: 1,
    }
  },
  components: {
    User,
    Buttons,
  },
  watch: {
    isSign(val){
      if(val){
        this.$refs.user.getUserCard()
      }
    }
  },
}
</script>

<style scoped lang="scss">
.user-card {
  width: 344px;
  height: 250px;
  padding-top: 16px;
  border: 1px solid #eff1f4;
}
</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "user" }, [
    _c("div", { staticClass: "avatar" }, [
      _c("img", { attrs: { src: _vm.userInfo.avatar_file, alt: "" } }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "name" }, [
      _vm._v(_vm._s(_vm.userInfo.user_name)),
    ]),
    _vm._v(" "),
    _c("ul", { staticClass: "info" }, [
      _c("li", [
        _c("a", { attrs: { href: "/personal?tab=collection" } }, [
          _c("div", [_vm._v(_vm._s(_vm.userCard.collection))]),
          _vm._v(" "),
          _c("span", [_vm._v("收藏")]),
        ]),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("a", { attrs: { href: "/personal" } }, [
          _c("div", [_vm._v(_vm._s(_vm.userCard.article))]),
          _vm._v(" "),
          _c("span", [_vm._v("帖子")]),
        ]),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("a", { attrs: { href: "/credit?tab=MyCredit" } }, [
          _c("div", [_vm._v(_vm._s(_vm.userCard.valid_integral))]),
          _vm._v(" "),
          _c("span", [_vm._v("积分")]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "user-card" },
    [
      _c("User", {
        ref: "user",
        attrs: { userInfo: _vm.$root.userInfo, isSign: _vm.isSign },
        on: {
          "update:isSign": function ($event) {
            _vm.isSign = $event
          },
          "update:is-sign": function ($event) {
            _vm.isSign = $event
          },
        },
      }),
      _vm._v(" "),
      _c("Buttons", {
        attrs: {
          isShowTip: _vm.isShowTip,
          isSign: _vm.isSign,
          isShowPopup: _vm.isShowPopup,
        },
        on: {
          "update:isSign": function ($event) {
            _vm.isSign = $event
          },
          "update:is-sign": function ($event) {
            _vm.isSign = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
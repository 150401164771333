<template>
  <ul class="pagination">
    <li><a href="javascript:void(0);" @click="$emit('update:page', current - 1 <= 0 ? 1 : current - 1)">&lt;</a></li>
    <li v-for="item in showingPage" :key="item">
      <a href="javascript:void(0);" @click="$emit('update:page', item)" :class="{ active: item === current }">
        {{ item }}
      </a>
    </li>
    <li>
      <a href="javascript:void(0);" @click="$emit('update:page', current + 1 > totalPage ? totalPage : current + 1)">
        &gt;
      </a>
    </li>
    <li>
      <select @change="handleSelectChange" v-model="currentSegment">
        <option :value="item" v-for="item in segments" :key="item">{{ item }}条/页</option>
      </select>
    </li>
    <li>跳至</li>
    <li>
      <input type="text" v-model="goto" @keydown.enter="jump($event)" @blur="jump" />
    </li>
    <li>页</li>
  </ul>
</template>

<script>
export default {
  name: 'pagination',
  data() {
    return {
      segments: [30, 50],
      currentSegment: 30,
      goto: 0,
      showingPage: [],
    }
  },
  props: {
    total: {
      type: Number,
      default: 0,
    },
    current: {
      type: Number,
      default: 0,
    },
    showNum: {
      type: Number,
      default: 9,
    },
  },
  watch: {
    current() {
      this.computeShowingPage()
    },
  },
  computed: {
    totalPage() {
      return Math.ceil(this.total / this.currentSegment)
    },
  },
  created() {
    this.computeShowingPage()
  },
  mounted() {
    this.goto = this.showNum
  },
  methods: {
    getArrayItems(start, end) {
      return Array.apply(null, { length: end - start + 1 }).map((item, index) => index + start)
    },
    jump(e) {
      e.target.blur()
      if (Number.isNaN(Number(this.goto))) {
        this.goto = this.showNum
      }
      this.$emit('update:page', parseInt(this.goto))
    },
    computeShowingPage() {
      let start = this.current,
        end = this.showNum
      if (this.showingPage.length) {
        let first = this.showingPage[0],
          last = this.showingPage[this.showingPage.length - 1]
        if (start >= first && start <= last) {
          return
        }
      }
      if (this.current > this.showNum) {
        start = this.current - this.showNum
        end = start + this.showNum
      } else {
        start = 1
        end = this.showNum
      }
      this.showingPage = this.getArrayItems(start, end)
    },
    handleSelectChange() {
      this.$emit('update:segment', this.currentSegment)
      this.$emit('update:page', 1)
      this.goto = ''
    },
  },
}
</script>

<style scoped lang="scss">
ul.pagination {
  margin: 0 auto;
  display: block;
  padding: 0;
  color: #000000;
  font-size: 14px;

  li {
    display: inline;

    a {
      display: inline-block;
      margin: 0 4px;
      float: left;
      width: 35px;
      height: 35px;
      text-align: center;
      line-height: 35px;
      text-decoration: none;
      color: #000000;
      border: 1px solid #eff1f4;
      border-radius: 2px;

      &.active,
      &:hover,
      &:active {
        color: #ffffff;
        background-color: #0099ff;
        border-radius: 2px;
      }
    }

    select {
      width: 88px;
      height: 35px;
      text-align: center;
      line-height: 35px;
      border: 1px solid #eff1f4;
      border-radius: 2px;
    }

    input {
      width: 51px;
      height: 35px;
      text-align: center;
      border: 1px solid #eff1f4;
      border-radius: 2px;
    }
  }
}
</style>
